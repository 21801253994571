<template>
  <Flicking
    :options="flicking_options"
    :tag="'div'"
    :viewportTag="'div'"
    :cameraTag="'div'"
    :plugins="plugins"
  >
    <template v-if="products && products.length">
      <div v-for="(items, index) in groupedItems" :key="`slider-item-${index}`">
        <div
          class="product-slider__container"
          v-for="product in items"
          :key="product.id"
        >
          <router-link
            :to="{ name: product.is_merch ? 'MerchProductById' : 'ProductById', params: { productId: product.id } }"
          >
            <div class="product-list-image">
              <img
                v-if="product.image"
                :src="apiUrl ? apiUrl + product.image : product.image"
                alt="product_image"
                style="max-width: 100%; max-height: 100%"
              />
              <img
                v-else
                src="@/assets/marketplace/no_image.svg"
                alt="no_image"
                style="max-width: 100%; max-height: 100%"
              />
            </div>
            <div
              v-if="product.availability.num_available > 0"
              class="product-list-availability"
            >
              В наличии: {{ product.availability.num_available }}
            </div>
            <div v-else class="product-list-availability not-available">
              Под заказ
            </div>
            <h5 class="product-list-title">{{ product.title }}</h5>
            <v-rating
              :value="product.rating"
              size="14"
              color="#EF7F1A"
              half-increments
              readonly
              dense
            ></v-rating>
            <div class="product-list-price">
              {{ typeof product.price === 'object' ? getProductPrice(product) : product.price }}
            </div>
          </router-link>
        </div>
      </div>
    </template>
    <div slot="viewport" class="flicking-pagination"></div>
  </Flicking>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import { Pagination, AutoPlay } from "@egjs/flicking-plugins"

const chunkArray = (array, size) => {
  const chunkedArray = [];
  const numChunks = Math.ceil(array.length / size);
  for (let i = 0; i < numChunks; i++) {
    const start = i * size;
    const end = start + size;
    chunkedArray.push(array.slice(start, end));
  }
  return chunkedArray;
};

export default {
  name: "ProductSlider",
  props: {
    products: {
      type: Array,
      default: () => []
    },
    apiUrl: String
  },
  components: {
    Flicking
  },
  data() {
    return {
      plugins: [
        new Pagination({ type: "bullet" }),
        new AutoPlay({
          duration: 5000,
          direction: 'NEXT'
        })
      ],
      flicking_options: {
        gap: 10,
        bound: true,
        panelsPerView: 1
      }
    }
  },
  computed: {
    groupedItems() {
      return chunkArray(this.products, 2);
    },
  },
  methods: {
    getProductPrice(product) {
      if(!product) return '-'
      const price = parseFloat(product.price?.['excl_tax']).toLocaleString("ru")
      const unit = product.is_merch ? 'E' : '₸'
      return `${price} ${unit}`
    },
  }
}
</script>

<style scoped lang="scss">
.product-slider {
  &__container {
    width: calc(50% - 5px);
    display: inline-block;
    margin-bottom: 14px;
    padding: 0 6px;
  }
}
</style>
