<template>
  <v-dialog v-model="dialogVisible" persistent width="320" data-app>
    <v-card style="border-radius: 10px">
      <v-card-title class="justify-center">
        <p style="font-size: 1.5rem; font-weight: 600">
          Выберите город
        </p>
      </v-card-title>
      <v-card-text class="text-center" style="padding-bottom: 8px">
        <p style="line-height: 1.1">Для того, чтобы ускорить процесс заказа, выберите свой город</p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          color="#EF7F1A"
          class="select-city__dialog__btn"
          @click="$router.push({ name: 'MarketplaceSelectCity' })"
        >
          Перейти к выбору
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.select-city__dialog {
  &__btn {
    height: 48px !important;
    border-radius: 10px;
    margin-bottom: 8px;

    font-weight: 600;
    font-size: 1rem !important;
    line-height: 1.1;
    letter-spacing: 0.02em;
    color: #FFFFFF !important;
    text-transform: none;
  }
}
</style>
