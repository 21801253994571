<template>
  <main
    class="layout__body no-padding"
    :class="{ 'prevent-scrolling': isSearchOpened }"
  >
    <header class="market-fixed-header" ref="header">
      <div class="market-header-card">
        <div class="market-header-title">
          <div class="market-header-with-back-btn">
            <v-skeleton-loader
              v-if="!parentCategory"
              type="text"
              class="skeleton-header"
              width="150"
            ></v-skeleton-loader>
            <span v-else class="market-header-category">
              {{ parentCategory.name }}
            </span>
          </div>
          <LimitTag />
        </div>
        <MarketSearch
          ref="marketSearch"
          :isListOfProducts="isListOfProducts"
          :categoryId="categoryId"
          :productCount="productsCount"
          :minPrice="minPrice"
          :maxPrice="maxPrice"
          @disableScroll="disableScroll"
          @enableScroll="enableScroll"
          @activateSkeletonLoader="activateSkeletonLoader"
          @deactivateSkeletonLoader="deactivateSkeletonLoader"
        />
        <div
          v-if="childCategory && childCategory.length"
          class="category-slider-container"
        >
          <router-link
            v-for="subcategory in childCategory"
            :key="subcategory.id"
            class="category-slider"
            :to="nextCategory(subcategory)"
          >
            {{ subcategory.name }}
          </router-link>
        </div>
      </div>
    </header>
    <div class="market-container" :style="{ marginTop: headerHeight + 8 + 'px' }">
      <div
        v-if="isProductsLoading"
        class="skeleton-list-of-products-container"
      >
        <div v-for="n in 4" :key="n" class="skeleton-item">
          <v-skeleton-loader
            type="image, list-item-two-line"
            class="skeleton-list-of-products"
          ></v-skeleton-loader>
        </div>
      </div>
      <div v-else-if="!isProductsLoading && productsList.length" class="product-list">
        <div class="product-list-amount">
          <span>Найдено товаров: </span>
          <strong>{{ productsCount }}</strong>
        </div>
        <div class="product-list-card-container">
          <div
            class="product-list-card"
            v-for="product in productsList"
            :key="product.id"
          >
            <router-link
              :to="{ name: 'ProductById', params: { productId: product.id } }"
            >
              <div class="product-list-image">
                <img
                  v-if="product.image"
                  :src="api_url + product.image"
                  alt="product_image"
                  style="max-width: 100%; max-height: 100%"
                />
                <img
                  v-else
                  src="@/assets/marketplace/no_image.svg"
                  alt="no_image"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
              <div
                v-if="product.availability.num_available > 0"
                class="product-list-availability"
              >
                В наличии: {{ product.availability.num_available }}
              </div>
              <div v-else class="product-list-availability not-available">
                Под заказ
              </div>
              <h5 class="product-list-title">{{ product.title }}</h5>
              <v-rating
                :value="product.rating"
                size="14"
                color="#EF7F1A"
                half-increments
                readonly
                dense
              ></v-rating>
              <div class="product-list-price">
                {{ parseFloat(product.price.excl_tax).toLocaleString("ru") }} ₸
              </div>
            </router-link>
            <button
              type="button"
              v-ripple="{ center: true }"
              class="add-to-cart-from-list-btn"
              @click="addToCartClicked(product)"
              v-if="in_cart_qty_by_id(product.id) === 0"
            >
              В корзину
            </button>
            <div
              v-else-if="in_cart_qty_by_id(product.id) > 0"
              class="in-cart-actions"
            >
              <button
                type="button"
                v-ripple="{ center: true }"
                class="remove-from-cart-btn"
                @click="removeFromCart(product.id)"
                v-if="in_cart_qty_by_id(product.id) === 1"
              >
                <img src="@/assets/marketplace/trash_can.svg" alt="trash" />
              </button>
              <button
                type="button"
                v-ripple="{ center: true }"
                class="remove-from-cart-btn"
                @click="removeFromCart(product.id)"
                v-else-if="in_cart_qty_by_id(product.id) > 1"
              >
                <span>-</span>
              </button>
              <span class="in-cart-amount">
                {{ in_cart_qty_by_id(product.id) }} шт.
              </span>
              <button
                type="button"
                v-ripple="{ center: true }"
                class="add-more-to-cart-btn"
                @click="addToCart({ id: product.id })"
              >
                <span>+</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <NoProducts v-else />
      <div
        v-if="!isProductsLoading && !isLastPage"
        v-intersect.quiet="onIntersect"
        class="skeleton-list-of-products-container"
      >
        <div v-for="n in 2" :key="n" class="skeleton-item">
          <v-skeleton-loader
            type="image, list-item-two-line"
            class="skeleton-list-of-products"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import MarketSearch from "@/components/marketplace/market_search.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";
import LimitTag from "@/components/marketplace/LimitTag.vue"
import NoProducts from "@/components/merch/NoProducts.vue"

export default {
  components: {
    LimitTag,
    MarketSearch,
    NoProducts,
  },
  props: {
    categoryId: [Number, String],
    subcategory: {
      type: Boolean,
      default: false,
    },
    sub_subcategory: {
      type: Boolean,
      default: false,
    },
    sub_sub_subcategory: {
      type: Boolean,
      default: false,
    },
    level: [Number, String],
  },
  data: () => ({
    headerHeight: undefined,
    isProductsLoading: false,
    isListOfProducts: true,
    isSearchOpened: false,
  }),
  computed: {
    ...mapState("marketplace", [
      "api_url",
      "limit",
      "cart",
      "wide_subcategories",
      "wide_sub_subcategories",
      "wide_sub_sub_subcategories",
      "subcategoryById",
      "sub_subcategoryById",
      "sub_sub_subcategoryById",
      "productsPaginationInfo",
      "productsByCategory",
      "filtersPaginationInfo",
      "filteredProducts",
    ]),
    ...mapGetters("marketplace", ["all_categories", "in_cart_qty_by_id"]),
    isLastPage() {
      if (this.filtersPaginationInfo) {
        if (this.filtersPaginationInfo.next) return false;
        else return true;
      } else if (this.productsPaginationInfo) {
        if (this.productsPaginationInfo.next) return false;
        else return true;
      } else return true;
    },
    parentCategory() {
      return this.all_categories.find((category) => {
        return category.id == this.categoryId;
      });
    },
    childCategory() {
      if (this.sub_sub_subcategory) {
        return this.sub_sub_subcategoryById;
      } else if (this.sub_subcategory) {
        return this.sub_subcategoryById;
      } else if (this.subcategory) {
        return this.subcategoryById;
      } else return undefined;
    },
    productsList() {
      if (this.filteredProducts) return this.filteredProducts;
      else return this.productsByCategory;
    },
    productsCount() {
      if (this.filtersPaginationInfo) return this.filtersPaginationInfo.count;
      else if (this.productsPaginationInfo) return this.productsPaginationInfo?.count;
      else return 0;
    },
    prices() {
      return this.productsList.map(product => product.price.excl_tax);
    },
    minPrice() {
      return this.prices.length ? Math.min(...this.prices) : 0;
    },
    maxPrice() {
      return this.prices.length ? Math.max(...this.prices) : 1;
    },
    lastLevel() {
      if (this.subcategory) return 1;
      else if (this.sub_subcategory) return 2;
      else if (this.sub_sub_subcategory) return 3;
      else return null;
    },
  },
  async created() {
    if (
      !this.filteredProducts?.length &&
      !this.productsByCategory?.length
    ) {
      if (this.level) {
        amplitudeMarketplace({
          event_type: "Инструменты - Переход в категорию",
          extra_info: {
            level: +this.level + 1,
            title: this.parentCategory.name
          },
          project_name: "Marketplace"
        });
      }
      await this.activateSkeletonLoader();
      await this.getProductsByFilter(this.categoryId);
      this.$refs.marketSearch.setMinMaxPrice();
      this.deactivateSkeletonLoader();
    }
  },
  mounted() {
    backNavigateQueue.set(this.backToCategories);
    this.getHeaderHeight();
  },
  methods: {
    ...mapActions("marketplace", [
      "reset_wide_subcategories",
      "getSubcategoryById",
      "getSubSubcategoryById",
      "getSubSubSubcategoryById",
      "getBrandsByCategory",
      "getProductsByFilter",
      "clearSubcategory",
      "clearSubSubcategory",
      "clearSubSubSubcategory",
      "clearProductsPaginationInfo",
      "clearListOfProducts",
      "addToCart",
      "removeFromCart",
      "clearBrandsByCategory",
      "clearQueryParams",
      "clearFiltersPaginationInfo",
      "clearProductsByFilter",
    ]),
    addToCartClicked(product) {
      this.addToCart({ id: product.id });
      amplitudeMarketplace({
        event_type: "Инструменты - Добавление товара в Корзину",
        extra_info: product.title,
        project_name: "Marketplace"
      });
    },
    activateSkeletonLoader() {
      this.isProductsLoading = true;
    },
    deactivateSkeletonLoader() {
      this.isProductsLoading = false;
    },
    onIntersect (entries, observer, isIntersecting) { // eslint-disable-line
      if (isIntersecting) {
        this.getProductsByFilter(this.categoryId);
      }
    },
    getHeaderHeight() {
      this.headerHeight = this.$refs.header.clientHeight;
      this.$forceUpdate();
    },
    backToCategories() {
      this.$router.go(-1);
    },
    disableScroll() {
      if (
        this.wide_subcategories ||
        this.wide_sub_subcategories ||
        this.wide_sub_sub_subcategories
      ) {
        this.$emit("disableScroll");
      } else {
        this.isSearchOpened = true;
      }
    },
    enableScroll() {
      if (
        this.wide_subcategories ||
        this.wide_sub_subcategories ||
        this.wide_sub_sub_subcategories
      ) {
        this.$emit("enableScroll");
      } else {
        this.isSearchOpened = false;
      }
    },
    nextCategory(subcategory) {
      if (subcategory.numchild > 0) {
        if (this.sub_subcategory) {
          return {
            name: 'Sub-sub-subcategory',
            params: { sub_sub_subcategoryId: subcategory.id }
          }
        } else return {
          name: 'Sub-subcategory',
          params: { sub_subcategoryId: subcategory.id }
        }
      } else {
        return {
          name: 'List of products',
          params: { categoryId: subcategory.id, level: this.lastLevel }
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === "Sub-sub-subcategory" ||
      to.name === "Sub-subcategory" ||
      to.name === "Subcategory"
    ) {
      this.clearProductsPaginationInfo();
      this.clearListOfProducts();
      this.clearBrandsByCategory();
      this.clearQueryParams();
      this.clearFiltersPaginationInfo();
      this.clearProductsByFilter();
      next();
    } else if (to.name !== "ProductById") {
      this.reset_wide_subcategories();
      this.clearSubcategory();
      this.clearSubSubcategory();
      this.clearSubSubSubcategory();
      this.clearProductsPaginationInfo();
      this.clearListOfProducts();
      this.clearBrandsByCategory();
      this.clearQueryParams();
      this.clearFiltersPaginationInfo();
      this.clearProductsByFilter();
      next();
    } else next();
  },
};
</script>

<style lang="scss" scoped>
.market-header-card, .market-container {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
