<template>
  <main
    v-if="product"
    class="layout__body no-padding"
    :class="{
      'prevent-scrolling': buyNowPanel || toCartPanel || isImagesFullscreen,
    }"
  >
    <header class="market-header-container product-header">
      <div class="market-header-card">
        <div class="market-header-title">
          <div class="market-header-with-back-btn">
            <span class="product-title-overflow">{{ product.title }}</span>
          </div>
          <LimitTag />
        </div>
      </div>
    </header>
    <div class="product-actions-margin">
      <div class="product-photo-carousel">
        <ImageSlider
          :api_url="api_url"
          :images="product.images"
          @openFullscreenImage="openFullscreenImage(product.images)"
        />
      </div>
      <div class="product-info-card">
        <div
          v-if="product && product.availability.num_available > 0"
          class="product-info-availability"
        >
          В наличии: {{ product.availability.num_available }}
        </div>
        <div v-else class="product-info-availability not-available">
          Под заказ
        </div>
        <h5 class="product-info-title">{{ product.title }}</h5>
        <div class="product-info-price">
          {{ parseFloat(productPrice).toLocaleString("ru") }} ₸
        </div>
        <v-rating
          :value="product.rating"
          size="16"
          color="#EF7F1A"
          half-increments
          readonly
          dense
        ></v-rating>
      </div>
      <div class="product-info-subtitle">Описание товара:</div>
      <div class="product-info-card">
        <div>
          <div
            v-if="product.description"
            v-html="product.description"
            class="product-info-description"
            :class="{ expanded: isDescriptionExpanded }"
          ></div>
          <div v-else>Для данного товара не было добавлено описание.</div>
          <div
            v-if="product.description && product.description.length > 50"
            class="product-expand-description"
            @click="isDescriptionExpanded = !isDescriptionExpanded"
          >
            {{ isDescriptionExpanded ? "Скрыть" : "Подробнее" }}
            <img
              v-if="isDescriptionExpanded"
              src="@/assets/marketplace/chevron_up.svg"
              alt="collapse"
              style="padding-left: 6px"
            />
            <img
              v-if="!isDescriptionExpanded"
              src="@/assets/marketplace/chevron_down.svg"
              alt="expand"
              style="padding-left: 6px"
            />
          </div>
        </div>
      </div>
      <div class="product-info-subtitle">Информация о товаре</div>
      <div class="product-info-card">
        <v-row
          no-gutters
          class="product-info-attributes"
          v-for="attr in filteredAttributes"
          :key="attr.code"
        >
          <v-col cols="6">{{ attr.name }}</v-col>
          <v-col cols="6">{{ attr.value }}</v-col>
        </v-row>
        <div
          class="product-expand-description"
          @click="isInformationExpanded = !isInformationExpanded"
          v-if="product && product.attributes.length > 3"
        >
          {{ isInformationExpanded ? "Скрыть" : "Показать полностью" }}
          <img
            v-if="isInformationExpanded"
            src="@/assets/marketplace/chevron_up.svg"
            alt="collapse"
            style="padding-left: 6px"
          />
          <img
            v-if="!isInformationExpanded"
            src="@/assets/marketplace/chevron_down.svg"
            alt="expand"
            style="padding-left: 6px"
          />
        </div>
      </div>
      <div
        v-if="filteredReviews && filteredReviews.length > 0"
        class="product-info-subtitle"
      >
        Отзывы
      </div>
      <div
        v-if="filteredReviews && filteredReviews.length > 0"
        class="reviews-container"
      >
        <div v-for="(review, index) in filteredReviews" :key="review.id">
          <div class="review-wrapper">
            <div class="review-header">
              <div class="review-header-text">
                <div class="review-header-title">
                  {{ review.user.full_name }}
                </div>
                <div class="review-header-date">
                  {{
                    new Date(review.date_created).toLocaleDateString("ru", {
                      day: "numeric",
                      month: "2-digit",
                      year: "2-digit",
                    })
                  }}
                </div>
              </div>
              <v-rating
                :value="review.score"
                size="16"
                color="#EF7F1A"
                half-increments
                readonly
                dense
              ></v-rating>
            </div>
            <div class="review-body">{{ review.body }}</div>
            <div
              v-if="review.images && review.images.length > 0"
              class="review-images-wrapper"
            >
              <div
                v-for="image in review.images"
                :key="image.id"
                class="review-images-container"
                @click="openFullscreenImage(review.images)"
              >
                <img
                  :src="api_url + image.image"
                  alt="product"
                  class="review-image"
                />
              </div>
            </div>
          </div>
          <hr v-if="index < 2" class="market-divider" />
        </div>
        <div v-if="productReviews && productReviews.length > 3">
          <hr class="market-divider" />
          <div class="review-wrapper more-reviews">
            <div @click="isReviewsExpanded = !isReviewsExpanded">
              <span v-if="isReviewsExpanded">Скрыть</span>
              <span v-else>
                Ещё {{ productReviews.length - filteredReviews.length }}
              </span>
              <img
                v-if="isReviewsExpanded"
                src="@/assets/marketplace/chevron_up.svg"
                alt="collapse"
                class="blue-chevron"
              />
              <img
                v-else
                src="@/assets/marketplace/chevron_down.svg"
                alt="expand"
                class="blue-chevron"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="product-actions-wrapper">
        <div class="product-actions">
          <button type="button" class="buy-now-btn" @click="openBuyNowPanel">
            Купить сейчас
          </button>
          <button
            type="button"
            class="add-to-cart-btn"
            @click="openAddToCartPanel"
          >
            В корзину
          </button>
        </div>
      </div>
      <div
        class="adding-to-cart-bg"
        :class="{ active: buyNowPanel || toCartPanel }"
        @click.self="stopBuying"
      >
        <div
          class="adding-to-cart-panel"
          :class="{ active: buyNowPanel || toCartPanel }"
        >
          <div class="adding-to-cart-info">
            <div class="close-panel-btn" @click="stopBuying">✖</div>
            <div class="adding-to-cart-image">
              <img
                v-if="product.images && product.images.length > 0"
                :src="api_url + product.images[0]"
                alt="product_image"
                style="max-width: 100%; max-height: 100%"
              />
              <img
                v-else
                src="@/assets/marketplace/no_image.svg"
                alt="no_image"
                style="max-width: 100%; max-height: 100%"
              />
            </div>
            <div style="margin-left: 8px">
              <div
                v-if="product && product.availability.num_available > 0"
                class="product-info-availability"
              >
                В наличии: {{ product.availability.num_available }}
              </div>
              <div v-else class="product-info-availability not-available">
                Под заказ
              </div>
              <p class="product-info-title" style="margin-bottom: 4px">
                {{ product.title }}
              </p>
              <div class="d-flex align-items-center">
                <div class="cart-item-actions">
                  <button
                    type="button"
                    v-ripple="{ center: true }"
                    class="cart-item-quantity-btn"
                    @click="removeItemFromCartQueue"
                    :disabled="cartQueue && cartQueue.quantity <= 1"
                  >
                    -
                  </button>
                  <span class="cart-item-quantity">
                    {{ cartQueueQuantity }} шт.
                  </span>
                  <button
                    type="button"
                    v-ripple="{ center: true }"
                    class="cart-item-quantity-btn"
                    @click="addItemToCartQueue"
                  >
                    +
                  </button>
                </div>
                <h5 class="cart-item-price" style="margin-left: 16px">
                  {{ parseFloat(cartQueuePrice).toLocaleString("ru") }} ₸
                </h5>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="buy-now-btn"
            style="width: 100%"
            @click="proceedToCheckout"
            v-if="buyNowPanel"
          >
            Перейти к оформлению
          </button>
          <button
            type="button"
            class="add-to-cart-btn"
            style="width: 100%"
            @click="addToCartFromCartQueue"
            v-if="toCartPanel"
          >
            Добавить в корзину
          </button>
        </div>
      </div>
    </div>
    <ImageSlider
      v-show="isImagesFullscreen"
      ref="imageSliderDialog"
      :api_url="api_url"
      :images="fullscreenImages"
      :isImagesFullscreen="isImagesFullscreen"
      @closeImages="closeFullscreenImage"
    />
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import ImageSlider from "@/components/marketplace/image_slider.vue";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";
import LimitTag from "@/components/marketplace/LimitTag.vue"

export default {
  props: {
    productId: [Number, String],
  },
  data: () => ({
    isImagesFullscreen: false,
    fullscreenImages: [],
    isDescriptionExpanded: false,
    isInformationExpanded: false,
    isReviewsExpanded: false,
    buyNowPanel: false,
    toCartPanel: false,
    cartQueue: undefined,
  }),
  components: {
    LimitTag,
    ImageSlider,
  },
  computed: {
    ...mapState("marketplace", [
      "api_url",
      "limit",
      "cart",
      "product",
      "productReviews",
    ]),
    productPrice() {
      return this.product ? this.product.price.excl_tax : 0;
    },
    filteredAttributes() {
      if (this.product?.attributes?.length <= 3) {
        return this.product.attributes;
      }
      if (this.product?.attributes?.length > 3) {
        return this.isInformationExpanded
          ? this.product.attributes
          : this.product.attributes.slice(0, 3);
      }
      return [];
    },
    filteredReviews() {
      if (this.productReviews?.length <= 3) {
        return this.productReviews;
      }
      if (this.productReviews?.length > 3) {
        return this.isReviewsExpanded
          ? this.productReviews
          : this.productReviews.slice(0, 3);
      }
      return [];
    },
    cartQueueQuantity() {
      return this.cartQueue ? this.cartQueue.quantity : 0;
    },
    cartQueuePrice() {
      return this.cartQueue
        ? this.cartQueue.quantity * this.cartQueue.product.price.excl_tax
        : 0;
    },
  },
  async created() {
    await this.getProduct(this.productId);
    this.getProductReviews(this.productId);
    amplitudeMarketplace({
      event_type: "Инструменты - Переход в карточку товара",
      extra_info: this.product.title,
      project_name: "Marketplace"
    });
  },
  mounted() {
    backNavigateQueue.set(this.backToProductsList);
  },
  methods: {
    ...mapActions("marketplace", [
      "getProduct",
      "getProductReviews",
      "addToCart",
      "reset_wide_categories",
      "clearSubcategory",
      "clearSubSubcategory",
      "clearSubSubSubcategory",
      "clearProductsPaginationInfo",
      "clearListOfProducts",
      "clearBrandsByCategory",
      "clearQueryParams",
      "clearFiltersPaginationInfo",
      "clearProductsByFilter",
      "clearProduct",
      "clearProductReviews",
    ]),
    backToProductsList() {
      this.$router.go(-1);
    },
    openFullscreenImage(images) {
      this.fullscreenImages = images;
      this.isImagesFullscreen = true;
    },
    closeFullscreenImage() {
      this.isImagesFullscreen = false;
      setTimeout(() => {
        this.fullscreenImages = [];
      }, 300);
    },
    openBuyNowPanel() {
      this.buyNowPanel = true;
      this.addItemToCartQueue(this.product);
    },
    openAddToCartPanel() {
      this.toCartPanel = true;
      this.addItemToCartQueue(this.product);
    },
    closeBuyingPanel() {
      this.buyNowPanel = false;
      this.toCartPanel = false;
    },
    addItemToCartQueue() {
      if (this.cartQueue?.quantity > 0) {
        let currentQuantity = this.cartQueue.quantity;
        this.cartQueue.quantity = ++currentQuantity;
      } else {
        this.cartQueue = {
          product: this.product,
          quantity: 1,
        };
      }
    },
    removeItemFromCartQueue() {
      if (this.cartQueue?.quantity > 1) {
        let currentQuantity = this.cartQueue.quantity;
        this.cartQueue.quantity = --currentQuantity;
      }
    },
    clearCartQueue() {
      this.cartQueue = undefined;
    },
    addToCartFromCartQueue() {
      this.addToCart({
        id: this.cartQueue.product.id,
        quantity: this.cartQueue.quantity,
      });
      amplitudeMarketplace({
        event_type: "Инструменты - Добавление товара в Корзину",
        extra_info: this.cartQueue.product.title,
        project_name: "Marketplace"
      });
      this.stopBuying();
    },
    proceedToCheckout() {
      this.addToCartFromCartQueue();
      this.$router.push({ name: "Cart" });
    },
    stopBuying() {
      this.clearCartQueue();
      this.closeBuyingPanel();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === "List of products" ||
      to.name === "Subcategory" ||
      to.name === "Sub-subcategory" ||
      to.name === "Sub-sub-subcategory"
    ) {
      this.clearProduct();
      this.clearProductReviews();
      next();
    } else {
      this.reset_wide_categories();
      this.clearSubcategory();
      this.clearSubSubcategory();
      this.clearSubSubSubcategory();
      this.clearProductsPaginationInfo();
      this.clearListOfProducts();
      this.clearBrandsByCategory();
      this.clearQueryParams();
      this.clearFiltersPaginationInfo();
      this.clearProductsByFilter();
      this.clearProduct();
      this.clearProductReviews();
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
