<template>
  <div>
    <v-bottom-navigation
      fixed
      height="64"
      color="#EF7F1A"
      :value="currentRoute"
    >
      <v-btn
        value="Market"
        :to="{
        name: 'Market',
        params: { isFromFooter: true },
        ...(limitType === 'office_supplies' && { query: { product_class: 'office_supplies' } })
      }"
      >
        <span>Категории</span>
        <v-icon>mdi-view-list</v-icon>
      </v-btn>
      <v-btn
        value="Cart"
        :to="{ name: 'Cart', params: { isFromFooter: true } }"
      >
        <span>Корзина</span>
        <v-badge
          :content="cart_qty_total"
          :value="cart_qty_total > 0"
          color="#F5463B"
          overlap
        >
          <v-icon> mdi-cart </v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        value="Orders"
        :to="{ name: 'Orders', params: { isFromFooter: true } }"
      >
        <span>Заказы</span>
        <v-icon>mdi-clipboard-text-outline</v-icon>
      </v-btn>
      <v-btn
        value="Support"
        :to="{ name: 'Support', params: { isFromFooter: true } }"
      >
        <span>Поддержка</span>
        <v-icon>mdi-headset</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapState("marketplace", ["limitType",]),
    currentRoute() {
      return this.$route.name;
    },
    ...mapGetters("marketplace", ["cart_qty_total"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
