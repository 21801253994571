var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-container",on:{"touchstart":function($event){$event.stopPropagation();},"touchend":function($event){$event.stopPropagation();},"touchmove":function($event){$event.stopPropagation();}}},[_c('Flicking',{attrs:{"options":{
      align: {
        panel: 0,
        camera: 16,
      },
      adaptive: true,
      interruptable: true,
      circular: true,
      collectStatistics: false,
      overflow: true,
    },"tag":'div',"viewportTag":'div',"cameraTag":'div',"preventClickOnDrag":true,"plugins":_vm.plugins}},[_c('div',{staticStyle:{"height":"118px","width":"calc(100% - 32px)","display":"flex","justify-content":"center"}},[_c('img',{attrs:{"src":require("@/assets/stationery_banner.svg"),"alt":""},on:{"click":function($event){return _vm.$router.push({ name: 'Discounts' })}}})]),_c('div',{staticClass:"flicking-pagination",attrs:{"slot":"viewport"},slot:"viewport"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }