<template>
  <div
    class="cart-container"
    :class="{ 'grey-bg': isOrdersAvailable }"
  >
    <div :class="{ 'market-header-container': isOrdersAvailable }">
      <v-card flat class="market-header-card">
        <v-card-text class="d-flex align-items-center justify-center">
          <div class="cart-header-title">
            <span>Заказы на согласование</span>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="!isApproveOrdersLoaded">
      <div v-for="(n, index) in 5" :key="n">
        <div class="skeleton-orders-container">
          <v-skeleton-loader
            type="text@2"
            class="skeleton-orders-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="avatar@3"
            class="skeleton-orders-images"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="heading, text"
            class="skeleton-orders-footer"
          ></v-skeleton-loader>
        </div>
        <hr v-if="index !== 4" class="market-divider" />
      </div>
    </div>
    <div v-else>
      <v-row no-gutters v-if="!isOrdersAvailable">
        <v-col cols="12" class="empty-cart">
          <v-icon size="56" color="#EF7F1A" class="empty-cart-icon">
            mdi-clock-outline
          </v-icon>
          <h5 class="empty-cart-title">Нет заказов на согласование</h5>
        </v-col>
      </v-row>
      <div class="market-container" :class="{ 'small-margin': !isLastPage }">
        <router-link
          v-for="(order, index) in approveOrders"
          :key="order.pk"
          :to="{ name: 'ApproveById', params: { id: order.pk } }"
        >
          <div class="order-card">
            <div class="order-title">
              <span
                class="order-status"
                :class="{
                  'red-status':
                    order.status === 'Не согласовано руководителем' ||
                    order.status === 'Отменено заказчиком' ||
                    order.status === 'Отменено поставщиком',
                  'green-status':
                    order.status === 'Выполнено',
                }"
              >
                {{ order.status }}
              </span>
              <span class="order-number"># {{ order.number }}</span>
            </div>
            <div class="order-photos">
              <div
                v-for="line in order.lines"
                :key="line.id"
                class="order-photo-container"
              >
                <img
                  v-if="line.product.image"
                  :src="api_url + line.product.image"
                  alt="product"
                  style="max-width: 100%; max-height: 100%"
                />
                <img
                  v-else
                  src="@/assets/marketplace/no_image.svg"
                  alt="no_image"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
            <div class="order-subtitle">
              <span class="order-price">
                {{ parseFloat(order.total_excl_tax).toLocaleString("ru") }} ₸
              </span>
              <span class="order-product-count">
                Количество товаров: {{ order.lines.length }}
              </span>
            </div>
          </div>
          <hr v-if="index !== approveOrders.length - 1" class="market-divider" />
        </router-link>
      </div>
    </div>
    <div
      v-if="isApproveOrdersLoaded && !isLastPage"
      v-intersect.quiet="onIntersect"
      style="margin-bottom: 72px; background-color: #FFFFFF"
    >
        <hr class="market-divider" />
        <div class="skeleton-orders-container">
          <v-skeleton-loader
            type="text@2"
            class="skeleton-orders-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="avatar@3"
            class="skeleton-orders-images"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="heading, text"
            class="skeleton-orders-footer"
          ></v-skeleton-loader>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";

export default {
  data: () => ({
    isApproveOrdersLoaded: false,
  }),
  computed: {
    ...mapState("marketplace", [
      "api_url",
      "approveOrders",
      "approveOrdersPaginationInfo",
    ]),
    ...mapGetters("marketplace", ["order__can_approve"]),
    isLastPage() {
      return this.approveOrdersPaginationInfo?.next ? false : true;
    },
    isOrdersAvailable() {
      if (this.approveOrders && this.approveOrders.length > 0) return true;
      else return false;
    },
  },
  async created() {
    this.clearApproveOrders();
    this.order__can_approve
      ? await this.getApproveOrders()
      : this.backToOrders();
    this.isApproveOrdersLoaded = true;
    let routerQuery = this.$router.currentRoute.query;
    if (routerQuery.event_type === 'order_approved') {
      amplitudeMarketplace({
        event_type: "Инструменты - Переход по PUSH уведомлению",
        extra_info: "На согласовании",
        project_name: "Marketplace"
      });
    }
  },
  mounted() {
    backNavigateQueue.set(this.backToOrders);
  },
  methods: {
    ...mapActions("marketplace", ["getApproveOrders", "clearApproveOrders"]),
    onIntersect (entries, observer, isIntersecting) { // eslint-disable-line
      if (isIntersecting) {
        this.getApproveOrders();
      }
    },
    backToOrders() {
      this.$router.replace({ name: 'Orders' });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-margin {
  margin-bottom: 0;
  border-radius: 16px 16px 0 0;
}
</style>