<template>
  <div class="layout__body layout__body--main">
    <div class="select-city__header">
      <div @click="exitFromMarket" style="cursor: pointer; margin-bottom: 1rem;">
        <img
          src="@/assets/marketplace/chevron_left.svg"
          alt="back"
          class="mr-8"
        />
        <span class="select-city__header_title">Выбор города</span>
      </div>
      <div>
        <input
          v-model="search"
          class="market-search-input"
          placeholder="Поиск"
        />
      </div>
    </div>
    <v-row no-gutters class="white-bg" style="border-radius: 10px">
      <template v-if="cities && cities.length > 0">
        <v-col
          cols="12"
          class="cart-select-item"
          v-for="city in filtered_cities"
          :key="city.id"
          @click="updateUserCity(city)"
        >
          <span>{{ city.name }}</span>
          <v-icon color="#EF7F1A" v-if="city.id === selected_city">
            mdi-check-circle
          </v-icon>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
          class="cart-select-item"
          style="justify-content: center"
        >
          Список городов пуст
        </v-col>
      </template>
      <div class="confirm-btn-container">
        <button
          type="button"
          class="confirm-btn"
          @click="exitFromMarket"
        >
          Готово
        </button>
      </div>
    </v-row>
  </div>
</template>

<script>
import { marketplaceApi } from "@/helpers/marketplaceApi.js"
import { backNavigateQueue } from "@/helpers/app.js"
import { mapState } from "vuex"

export default {
  data() {
    return {
      search: '',
      cities: [],
      selected_city: null
    }
  },
  async mounted() {
    backNavigateQueue.set(this.exitFromMarket)
    await this.getCityList()
    if(this.city !== null) this.selected_city = this.city.id
  },
  computed: {
    ...mapState('marketplace', ['city']),
    filtered_cities() {
      return this.cities.filter(city => city.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods: {
    exitFromMarket() {
      this.$router.go(-1);
    },
    async getCityList() {
      try {
        const { data } = await marketplaceApi.get('/api/cities')
        this.cities = data ?? []
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async updateUserCity(city) {
      try {
        const { data } = await marketplaceApi.patch('/api/update-city/', {
          city_id: city.id
        })
        if(data.success) {
          this.selected_city = city.id
          this.$store.commit('marketplace/setCity', city)
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    }
  }
};
</script>

<style lang="scss">
.select-city {

  &__header {
    margin-bottom: 8px;
    padding: 1rem;
    background-color: #FFF;
    border-radius: 0 0 12px 12px;

    &_title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}
.confirm-btn-container {
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.confirm-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
</style>
