<template>
  <div class="layout__body layout__body--main" data-app>
    <div class="store__header">
      <div style="display: flex; align-items: center">
        <img
          src="@/assets/marketplace/chevron_left.svg"
          alt="back"
          class="mr-8"
          @click="$router.go(-1)"
        />
        <span class="store__header_title">Скидки</span>
      </div>
    </div>
    <div class="discounts mt-8">
      <h2 class="discounts-title">О промокодах</h2>
      <div class="discounts-details">
        <p style="font-size: 16px">
          Получите доступ к эксклюзивным промокодам на скидки только для
          работников ERG!
        </p>
        <p style="font-size: 16px">
          Осуществляйте покупки в канцелярских магазинах Abdi и Asem по всему
          Казахстану с существенной экономией!
        </p>
      </div>
    </div>
    <div class="discounts mt-8 gap-2">
      <h2 class="discounts-title">Abdi</h2>
      <div v-if="marketUser.promo_code_abdi" class="discounts-banner abdi">
        <div class="d-flex justify-between">
          <div class="discounts-banner-title">
            Скидка 12% по промокоду
            <span class="highlight">{{ marketUser.promo_code_abdi }}</span>
          </div>
          <img
            src="@/assets/marketplace/brands/abdi-white.png"
            alt="abdi"
            class="brand-img"
          />
        </div>
        <barcode
          :value="marketUser.promo_code_abdi"
          :options="{ displayValue: false }"
          class="discounts-barcode"
        ></barcode>
        <div class="discounts-banner-subtitle">
          Действует во всех оффлайн-магазинах Abdi
        </div>
      </div>
      <div v-else>Нет активных промокодов от данного поставщика</div>
    </div>
    <div class="discounts mt-8">
      <h2 class="discounts-title">Asem.kz</h2>
      <div class="discounts-banner assem">
        <div class="d-flex justify-between">
          <div class="discounts-banner-title">
            Скидка 15% по промокоду <span class="highlight">ERGMARKET</span>
          </div>
          <img
            src="@/assets/marketplace/brands/asem-white.png"
            alt="assem"
            class="brand-img"
          />
        </div>
        <div class="discounts-banner-subtitle">
          Действует в интернет-магазине и во всех оффлайн-магазинах Asem.kz
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("marketplace", ["marketUser"]),
  },
};
</script>

<style lang="scss" scoped>

.store {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #FFF;
    border-radius: 0 0 12px 12px;

    &_title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}

.discounts {
  gap: 12px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #ffffff;

  &-title {
    margin: 0;
    font-size: 20px;
    line-height: 1.5rem;
    font-weight: 600;
  }

  &-details {
    font-size: 16px;
    line-height: 24px;
  }

  &-barcode {
    height: 50px;
    width: -webkit-fill-available;
  }

  &-banner {
    gap: 12px;
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;

    &.abdi {
      background: linear-gradient(180deg, #2a4a90 22.4%, #3968cd 100%);

      .highlight {
        color: #93ed20;
      }
    }

    &.assem {
      background: linear-gradient(180deg, #2bad7e 22.4%, #32c5aa 100%);

      .highlight {
        color: #e2dd58;
      }
    }

    &-title {
      width: 200px;
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #ffffff;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }
}

.brand-img {
  height: 20px;
  object-fit: contain;
}
</style>