<template>
  <div class="layout__body layout__body--main" data-app>
    <SelectCityDialog
      :dialog-visible.sync="dialogVisible"
    />
    <div class="store__header">
      <div @click="exitFromMarket" style="display: flex; align-items: center">
        <img
          src="@/assets/marketplace/chevron_left.svg"
          alt="back"
          class="mr-8"
        />
        <span class="store__header_title">Главная</span>
      </div>
      <div
        class="d-flex align-items-center"
        style="color: #EF7F1A; font-weight: 500; cursor: pointer;"
        @click="$router.push({ name: 'MarketplaceSelectCity' })"
      >
        <span class="d-flex align-items-center" style="gap: 8px">
          <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.70002 4.05C7.29676 4.05 7.86906 4.28705 8.29101 4.70901C8.71297 5.13097 8.95002 5.70326 8.95002 6.3C8.95002 6.59547 8.89183 6.88806 8.77875 7.16104C8.66568 7.43402 8.49995 7.68206 8.29101 7.89099C8.08208 8.09992 7.83404 8.26566 7.56106 8.37873C7.28808 8.4918 6.9955 8.55 6.70002 8.55C6.10329 8.55 5.53099 8.31295 5.10903 7.89099C4.68708 7.46903 4.45002 6.89674 4.45002 6.3C4.45002 5.70326 4.68708 5.13097 5.10903 4.70901C5.53099 4.28705 6.10329 4.05 6.70002 4.05ZM6.70002 0C8.37089 0 9.97332 0.663748 11.1548 1.84523C12.3363 3.02671 13 4.62914 13 6.3C13 11.025 6.70002 18 6.70002 18C6.70002 18 0.400024 11.025 0.400024 6.3C0.400024 4.62914 1.06377 3.02671 2.24525 1.84523C3.42673 0.663748 5.02916 0 6.70002 0ZM6.70002 1.8C5.50655 1.8 4.36196 2.27411 3.51804 3.11802C2.67413 3.96193 2.20002 5.10653 2.20002 6.3C2.20002 7.2 2.20002 9 6.70002 15.039C11.2 9 11.2 7.2 11.2 6.3C11.2 5.10653 10.7259 3.96193 9.882 3.11802C9.03809 2.27411 7.8935 1.8 6.70002 1.8Z" fill="#EF7F1A"/>
          </svg>
          {{ city ? city.name : 'Не выбран' }}
          <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7.5L4 4.5L1 1.5" stroke="#EF7F1A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
    </div>
    <div class="mt-8 store__slider_container">
      <MainSlider/>
    </div>
    <div class="store__categories mt-8">
      <h2>Магазины</h2>
      <div style="display: flex; gap: 1rem; flex-wrap: wrap">
        <div class="store__categories_item" @click="handleRouteChange('Market')">
          <p>Инструменты</p>
          <img
            src="@/assets/store_tools.png"
            alt="tools"
            class="store__categories_item__img"
          />
        </div>
        <div class="store__categories_item" @click="handleRouteChange('Merch')">
          <p>Мерч</p>
          <img
            src="@/assets/store_t-shirt-1.png"
            alt="t-shirt-1"
            class="store__categories_item__img is-second"
          />
          <img
            src="@/assets/store_t-shirt-2.png"
            alt="t-shirt-2"
            class="store__categories_item__img"
          />
          <div class="store__categories_item__info">
            Покупка за ERG Coins
          </div>
        </div>
        <div class="store__categories_item" @click="handleRouteChange('Market', { product_class: 'office_supplies' })">
          <p>Канцтовары</p>
          <img
            src="@/assets/store_stationery.png"
            alt="stationery"
            class="store__categories_item__img is-stationery"
          />
        </div>
        <div class="store__categories_item" @click="handleRouteChange('Discounts')">
          <p>Скидки</p>
          <img
            src="@/assets/store_discounts.png"
            alt="discounts"
            class="store__categories_item__img is-discounts"
          />
        </div>
      </div>
    </div>
    <div class="store__categories mt-8" v-if="products.length > 0">
      <h2>Недавно просмотренные</h2>
      <ProductSlider
        :products="products"
      />
    </div>
    <div class="store__categories mt-8" v-if="popular_products.length > 0">
      <h2>Популярные товары</h2>
      <ProductSlider
        :products="popular_products"
        :api-url="api_url"
      />
    </div>
    <div class="store__categories mt-8">
      <h2>F.A.Q</h2>
      <v-expansion-panels class="store__panel">
        <v-expansion-panel
          v-for="(question, i) in questions"
          :key="`q-${i}`"
        >
          <v-expansion-panel-header color="#FAFAFA">
            {{ question.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#FAFAFA">
            {{ question.text }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="store__categories mt-8">
      <h2>Бренды</h2>
      <div class="store__brands">
        <img
          v-for="brand in brands"
          :key="brand"
          :src="require(`@/assets/marketplace/brands/${brand}.png`)"
          :alt="brand"
          style="width: 50%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { backNavigateQueue } from "@/helpers/app.js"
import { merchApi } from "@/helpers/merchApi.js"
import { marketplaceApi } from "@/helpers/marketplaceApi.js"
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";
import ProductSlider from "@/components/marketplace/product_slider.vue"
import MainSlider from "@/components/marketplace/main_slider.vue"
import SelectCityDialog from "@/components/marketplace/SelectCityDialog.vue"
// import SelectCityDialog from "@/components/marketplace/SelectCityDialog.vue"

export default {
  name: "Store",
  // components: { SelectCityDialog, MainSlider, ProductSlider },
  components: { SelectCityDialog, MainSlider, ProductSlider },
  data() {
    return {
      brands: ['licota', 'garwin-pro', 'garwin-ind', 'techrim', 'specx', 'makita'],
      products: JSON.parse(localStorage.getItem('viewed-items') || "[]"),
      popular_products: [],
      dialogVisible: false,
      questions: [
        {
          title: 'Если я не нашел нужный мне Инструмент, что делать?',
          text: 'Обратиться к поставщику, во вкладке Поддержка указаны контакты.'
        },
        {
          title: 'Вопросы по лимиту (отсутствует/заканчивается/меньше, чем выделено нашему цеху на этот год)',
          text: 'Необходимо обратиться к специалистам по развитию ТОиР на вашем предприятии.'
        },
        {
          title: 'Могу ли я купить инструменты не через Маркетплейс?',
          text: 'Все инструменты должны закупаться через Маркетплейс. Стандартным закупом могут закупаться только инструменты, относящиеся к основным средствам либо утвержденные в рамках стратегий.'
        },
        {
          title: 'Что делать, если инструмент пришел бракованный либо не соответствующий заказанному?',
          text: 'Можно отказаться от приемки на месте или подать рекламацию по стандартному процессу.'
        },
      ]
    }
  },
  async mounted() {
    backNavigateQueue.set(this.exitFromMarket)
    await this.getUserInfo()
    await this.getPopularProducts()

    if(this.city === null) this.dialogVisible = true
  },
  computed: {
    ...mapState('mainPage', ['slider', 'favourite']),
    ...mapState('marketplace', ['api_url', 'marketUser', 'city']),
  },
  methods: {
    ...mapActions("merch", ["getUserInfo"]),
    handleRouteChange(name, query = {}) {
      if (name === 'Market') {
        amplitudeMarketplace({
          event_type: "Переход в режим Инструменты",
          extra_info: null,
          project_name: "Marketplace"
        });
      } else {
        amplitudeMarketplace({
          event_type: "Переход в режим Мерч",
          extra_info: null,
          project_name: "Merch"
        });
      }
      this.$router.push({ name, query })
    },
    exitFromMarket() {
      this.$router.replace({ name: 'MainPage' });
    },
    onBurgerClick() {
      this.$store.dispatch('layout/toggleBurgerContent')
    },
    onNotificationClick() {
      this.$router.replace({
        name: 'NotificationsPage'
      })
    },
    getProductPrice(product) {
      if(!product) return '-'
      const price = parseFloat(product.price?.['excl_tax']).toLocaleString("ru")
      const unit = product.is_merch ? 'E' : '₸'
      return `${price} ${unit}`
    },
    async getPopularProducts() {
      const results = await Promise.allSettled([this.getPopularMerchProducts(), this.getPopularMarketplaceProducts()])
      for (const result of results) {
        this.popular_products = [...this.popular_products, ...(result.status === 'fulfilled' ? result.value : [])]
      }
      console.log(this.popular_products)
    },
    async getPopularMerchProducts() {
      try {
        const { data } = await merchApi.get('/merch/api/popular')
        return (data || []).map(product => ({ ...product, is_merch: true }))
      } catch (e) {
        throw new Error(e)
      }
    },
    async getPopularMarketplaceProducts() {
      try {
        const { data } = await marketplaceApi.get('/api/popular')
        return data || []
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.store {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #FFF;
    border-radius: 0 0 12px 12px;

    &_title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    &__icon {
      width: 28px;
      height: 28px;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }

  &__slider {
    &_container {
      background-color: #FFF;
      padding: 1rem 0;
      border-radius: 12px;
    }
  }

  &__categories {
    background-color: #FFF;
    padding: 0.75rem 1rem;
    //display: flex;
    //flex-wrap: wrap;
    border-radius: 12px;

    h2 {
      font-size: 20px;
      line-height: 1.5rem;
      font-weight: 600;
      margin-bottom: .75rem;
      flex: 1 1 100%;
    }

    &_item {
      background-color: #F3F5F9;
      border-radius: 8px;
      width: calc(50% - .5rem);
      padding: 1rem;
      position: relative;
      min-height: clamp(115px, 28vw, 250px);
      cursor: pointer;

      p {
        font-weight: 500;
      }

      &__img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(20vw + 50px);
        height: auto;

        &.is-second {
          padding-left: 2.5rem;
        }
        &.is-stationery,
        &.is-discounts {
          left: 0;
          right: 0;
          width: 100%;
          transform: none;
          border-radius: 8px;
        }
      }
      &__info {
        background-color: #A3D7FC;
        color: #3376F6;
        border-radius: 10px;
        padding: 4px 12px;
        position: absolute;
        left: 12px;
        bottom: 12px;
        font-size: clamp(11px, 2vw, 14px);
        max-width: 150px;
        line-height: 22px;
        font-weight: 500;
      }
    }
  }

  &__brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__panel {
    .v-expansion-panel {
      border-radius: 4px !important;

      &:before {
        box-shadow: none !important;
      }
      &:not(:first-child) {
        margin-top: 8px;
        &::after {
          content: none;
        }
      }
      &-header {
        border-radius: 4px !important;
        justify-content: space-between;
        text-align: left;
        font-weight: 500;
        padding: 0 1rem;
      }
      &-content {
        color: #8E8E93 !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }
  }
}
</style>
