<template>
  <div
    data-app
    class="market-search-container"
    :class="{ 'pos-rel': inputFocused }"
  >
    <input
      v-model="searchQuery"
      class="market-search-input"
      placeholder="Поиск по магазину"
      @focus="openSearch"
      @keyup.enter="startSeach"
    />
    <div
      class="market-search-actions"
      :class="{ 'list-of-products': isListOfProducts }"
    >
      <button
        v-if="trimmedSearch"
        type="button"
        class="market-search-btn"
        @click="startSeach"
      >
        Поиск
      </button>
      <button
        v-if="inputFocused"
        type="button"
        class="market-cancel-search-btn"
        @click="cancelSearch"
      >
        <img src="@/assets/marketplace/cancel_icon.svg" alt="cancel" />
      </button>
    </div>
    <div v-if="inputFocused" class="search-result-container">
      <template v-if="searchResult && searchResult.length > 0">
        <router-link
          v-for="product in searchResult"
          :key="product.id"
          :to="{ name: 'ProductById', params: { productId: product.id } }"
        >
          <div class="search-product">{{ product.title }}</div>
          <hr class="market-divider" />
        </router-link>
      </template>
      <template v-if="searchResult && searchResult.length === 0">
        <div v-if="emptyResult" class="search-product">{{ emptyResult }}</div>
      </template>
    </div>
    <div v-if="isListOfProducts" class="market-sort-filter-container">
      <button type="button" style="height: 26px" @click="openSortingPanel">
        <img src="@/assets/marketplace/sort_icon.svg" alt="sort" />
      </button>
      <button type="button" style="height: 26px" @click="openFilterPanel">
        <img
          src="@/assets/marketplace/filter_icon.svg"
          alt="filter"
          style="margin-left: 16px"
        />
      </button>
    </div>
    <div
      v-if="isListOfProducts"
      class="sorting-panel-bg"
      :class="{ active: isSortingOpen }"
      @click.self="closeSortingPanel"
    >
      <div class="sorting-panel" :class="{ active: isSortingOpen }">
        <div
          class="sorting-list"
          :class="{ 'with-btn': queryParams.has('order_by') }"
        >
          <div
            class="sorting-option"
            :class="{
              'selected-sorting-option':
                queryParams.get('order_by') === sortOption.code,
            }"
            v-for="sortOption in sortingOptions"
            :key="sortOption.code"
            @click="setFilterQuery('order_by', sortOption.code)"
          >
            {{ sortOption.title }}
            <img
              v-if="queryParams.get('order_by') === sortOption.code"
              src="@/assets/marketplace/check.svg"
              alt="check"
            />
          </div>
        </div>
        <button
          v-if="queryParams.has('order_by')"
          type="button"
          class="confirm-sorting-btn"
          @click="resetSorting"
        >
          <span class="confirm-sorting-text">Сбросить</span>
        </button>
      </div>
    </div>
    <div
      v-if="isListOfProducts"
      class="market-filter-container"
      :class="{ active: isFilteringOpen }"
    >
      <div class="market-header-container">
        <div class="market-header-card">
          <div class="market-header-title">
            <div style="margin: 0 auto">
              <div class="header-left-icon">
                <img
                  v-if="isFilterSelecting"
                  src="@/assets/marketplace/chevron_left.svg"
                  alt="back"
                  @click="closeFilterSelection"
                />
                <img
                  v-else
                  src="@/assets/marketplace/cancel_icon.svg"
                  alt="back"
                  @click="closeFilterPanel"
                />
              </div>
              <span class="market-header-category">
                {{ isFilterSelecting ? filterTitle : "Фильтр" }}
              </span>
              <button
                v-if="!isFilterSelecting && queryParams.toString().length"
                type="button"
                class="header-right-icon"
                @click="resetFilters"
              >
                Сбросить
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isFilterSelecting" class="filter-list">
        <div @click="openFilterSelection(brandsList)" class="filter-option">
          <div class="filter-option-text">
            <span class="filter-option-title">Бренд</span>
            <span class="filter-option-subtitle">
              <span v-for="(brand, index) in selectedBrands" :key="brand">
                {{ brand }}
                {{ index !== selectedBrands.length - 1 ? ',' : '' }}
              </span>
            </span>
          </div>
          <img src="@/assets/marketplace/chevron_right.svg" alt="right" />
        </div>
        <hr class="market-divider" />
        <div class="filter-option">
          <div class="filter-option-text">
            <span class="filter-option-title">Наличие</span>
            <div class="filter-option-btn-wrapper">
              <button
                type="button"
                class="filter-option-btn"
                :class="{ active: queryParams.get('is_available') === 'true' }"
                style="margin-right: 8px"
                @click="setFilterQuery('is_available', 'true')"
              >
                В наличии
              </button>
              <button
                type="button"
                class="filter-option-btn"
                :class="{ active: queryParams.get('is_available') === 'false' }"
                @click="setFilterQuery('is_available', 'false')"
              >
                Под заказ
              </button>
            </div>
          </div>
        </div>
        <hr class="market-divider" />
        <div class="filter-option">
          <div class="filter-option-text">
            <span class="filter-option-title">Отзывы</span>
            <div class="filter-option-btn-wrapper">
              <button
                type="button"
                class="filter-option-btn"
                :class="{ active: queryParams.get('with_reviews') === 'true' }"
                style="margin-right: 8px"
                @click="setFilterQuery('with_reviews', 'true')"
              >
                С отзывами
              </button>
              <button
                type="button"
                class="filter-option-btn"
                :class="{ active: queryParams.get('with_reviews') === 'false' }"
                @click="setFilterQuery('with_reviews', 'false')"
              >
                Без отзывов
              </button>
            </div>
          </div>
        </div>
        <hr class="market-divider" />
        <div class="filter-option">
          <div class="filter-option-text">
            <span class="filter-option-title">Цена</span>
            <div class="filter-option-input-wrapper">
              <div style="margin-right: 4px">
                <span class="filter-option-input-label">от</span>
                <input
                  type="number"
                  class="filter-option-input"
                  style="padding-left: 34px"
                  :value="priceRange ? priceRange[0] : 0"
                  @change="changeMinPrice"
                />
              </div>
              <div style="margin-left: 4px">
                <span class="filter-option-input-label">до</span>
                <input
                  type="number"
                  class="filter-option-input"
                  style="padding-left: 36px"
                  :value="priceRange ? priceRange[1] : 1"
                  @change="changeMaxPrice"
                />
              </div>
            </div>
            <v-range-slider
              v-model="priceRange"
              @change="changePriceRange"
              :min="initialPriceRange ? initialPriceRange[0] : 0"
              :max="initialPriceRange ? initialPriceRange[1] : 1"
              hide-details
              step="100"
              color="#ef7f1a"
            ></v-range-slider>
          </div>
        </div>
        <!-- New filters should be placed here -->
        <div class="confirm-filters-btn-container">
          <button
            type="button"
            class="confirm-filters-btn"
            @click="closeFilterPanel"
          >
            Показать позиции: {{ productCount ? productCount : 0 }}
          </button>
        </div>
      </div>
      <div v-else class="filter-list">
        <div v-for="(filter, index) in filterOptions" :key="filter">
          <div @click="setSelectedBrands(filter)" class="filter-option">
            <div class="filter-option-text">
              <span class="filter-option-title">{{ filter }}</span>
            </div>
            <input
              type="checkbox"
              :checked="selectedBrands.includes(filter)"
            />
          </div>
          <hr
            v-if="index !== filterOptions.length - 1"
            class="market-divider"
          />
        </div>
        <div class="confirm-filters-btn-container">
          <button
            type="button"
            class="confirm-filters-btn"
            @click="closeFilterPanel"
          >
            Показать позиции: {{ productCount ? productCount : 0 }}
          </button>
        </div>
      </div>
    </div>
    <LoadingDialog ref="loadingDialog" />
  </div>
</template>

<script>
import LoadingDialog from "@/components/marketplace/loading_dialog.vue";
import { mapState, mapActions } from "vuex";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";

export default {
  props: {
    isListOfProducts: Boolean,
    categoryId: [Number, String],
    productCount: Number,
    minPrice: Number,
    maxPrice: Number,
  },
  data: () => ({
    searchQuery: "",
    isSearched: false,
    inputFocused: false,
    isSortingOpen: false,
    isFilteringOpen: false,
    isFilterSelecting: false,
    sortingOptions: [
      { title: "Сначала дешёвые", code: "price_inc" },
      { title: "Сначала дорогие", code: "price_dec" },
    ],
    filterTitle: "",
    filterOptions: [],
    selectedBrands: [],
    is_available: undefined,
    with_reviews: undefined,
    initialPriceRange: undefined,
    priceRange: undefined,
    filteredPriceRange: undefined,
  }),
  components: {
    LoadingDialog,
  },
  computed: {
    ...mapState("marketplace", [
      "api_url",
      "searchResult",
      "brandsList",
      "queryParams",
      "filteredProducts",
    ]),
    trimmedSearch() {
      return this.searchQuery.trim();
    },
    emptyResult() {
      if (this.isSearched) return "По вашему запросу ничего не найдено";
      else return "";
    },
    minPriceRounded() {
      return Math.floor(this.minPrice / 100) * 100;
    },
    maxPriceRounded() {
      return Math.ceil(this.maxPrice / 100) * 100;
    },
  },
  created() {
    if (this.isListOfProducts) {
      this.getBrandsByCategory(this.categoryId);
    }
  },
  beforeDestroy() {
    this.cancelSearch();
  },
  methods: {
    ...mapActions("marketplace", [
      "searchForProduct",
      "clearSearchResult",
      "getBrandsByCategory",
      "setQueryParams",
      "deleteQueryParam",
      "clearQueryParams",
      "getProductsByFilter",
      "clearFiltersPaginationInfo",
      "clearProductsByFilter",
      "clearProductsPaginationInfo",
      "clearListOfProducts",
    ]),
    async startSeach() {
      if (this.trimmedSearch !== "") {
        await this.searchForProduct(this.trimmedSearch);
        this.isSearched = true;
        amplitudeMarketplace({
          event_type: "Инструменты - Поисковые запросы",
          extra_info: null,
          project_name: "Marketplace"
        });
      }
    },
    openSearch() {
      this.inputFocused = true;
      this.$emit("disableScroll");
    },
    cancelSearch() {
      this.inputFocused = false;
      this.isSearched = false;
      this.searchQuery = "";
      this.clearSearchResult();
      this.$emit("enableScroll");
    },
    openSortingPanel() {
      this.isSortingOpen = true;
      this.$emit("disableScroll");
    },
    closeSortingPanel() {
      this.isSortingOpen = false;
      this.$emit("enableScroll");
    },
    async resetSorting() {
      await this.deleteQueryParam('order_by');
      this.applyFiltering();
    },
    openFilterPanel() {
      this.isFilteringOpen = true;
      this.$emit("disableScroll");
    },
    closeFilterPanel() {
      if (this.isFilterSelecting) this.closeFilterSelection();
      this.isFilteringOpen = false;
      this.$emit("enableScroll");
    },
    async resetFilters() {
      this.selectedBrands = [];
      await this.deleteQueryParam('brand');
      await this.deleteQueryParam('is_available');
      await this.deleteQueryParam('with_reviews');
      await this.deleteQueryParam('price_min');
      await this.deleteQueryParam('price_max');
      await this.applyFiltering();
      this.setMinMaxPrice();
      this.closeFilterPanel();
    },
    openFilterSelection(filters) {
      this.isFilterSelecting = true;
      this.filterTitle = filters[0].name;
      this.filterOptions = filters.map((item) => item.value);
    },
    closeFilterSelection() {
      this.isFilterSelecting = false;
      this.filterTitle = "";
      this.filterOptions = [];
    },
    setMinMaxPrice() {
      this.initialPriceRange = [this.minPriceRounded, this.maxPriceRounded];
      this.priceRange = [this.minPriceRounded, this.maxPriceRounded];
    },
    resetPriceRange() {
      this.priceRange = this.initialPriceRange;
    },
    changePriceRange(array) {
      this.priceRange = array;
      this.setPriceQuery();
    },
    changeMinPrice(event) {
      if (event.target.valueAsNumber <= this.initialPriceRange[0]) {
        this.priceRange = [this.initialPriceRange[0], this.priceRange[1]];
      } else if (event.target.valueAsNumber >= this.priceRange[1]) {
        this.priceRange = [this.priceRange[1] - 1, this.priceRange[1]];
      } else if (isNaN(event.target.valueAsNumber)) {
        this.priceRange = [event.target._value, this.priceRange[1]];
      } else {
        this.priceRange = [event.target.valueAsNumber, this.priceRange[1]];
      }
      this.setPriceQuery();
    },
    changeMaxPrice(event) {
      if (event.target.valueAsNumber >= this.initialPriceRange[1]) {
        this.priceRange = [this.priceRange[0], this.initialPriceRange[1]];
      } else if (event.target.valueAsNumber <= this.priceRange[0]) {
        this.priceRange = [this.priceRange[0], this.priceRange[0] + 1];
      } else if (isNaN(event.target.valueAsNumber)) {
        this.priceRange = [this.priceRange[0], event.target._value];
      } else {
        this.priceRange = [this.priceRange[0], event.target.valueAsNumber];
      }
      this.setPriceQuery();
    },
    async setSelectedBrands(value) {
      // if (this.selectedBrands.includes(value)) {
      //   this.selectedBrands = this.selectedBrands.filter((item) => {
      //     return value !== item;
      //   });
      // } else {
      //   this.selectedBrands = [...this.selectedBrands, value];
      // }
      // let brandsArray = [];
      // this.selectedBrands.forEach((item) => {
      //   brandsArray = [...brandsArray, { name: 'brand', value: item }];
      // });
      // this.setQueryParams(brandsArray);
      if (this.selectedBrands.includes(value)) {
        this.selectedBrands = [];
        this.deleteQueryParam('brand');
      } else {
        this.selectedBrands = [value];
        let brandsArray = [];
        this.selectedBrands.forEach((item) => {
          brandsArray = [{ name: 'brand', value: item }];
        });
        this.setQueryParams(brandsArray);
      }
      await this.applyFiltering();
    },
    async setFilterQuery(name, value) {
      if (this.queryParams.get(name) === value) {
        this.deleteQueryParam(name);
      } else {
        this.setQueryParams({ name, value });
      }
      this.$forceUpdate();
      await this.applyFiltering();
    },
    setPriceQuery() {
      this.setQueryParams({ name: 'price_min', value: this.priceRange[0] });
      this.setQueryParams({ name: 'price_max', value: this.priceRange[1] });
      if (
        this.priceRange[0] === this.initialPriceRange[0] &&
        this.priceRange[1] === this.initialPriceRange[1]
      ) {
        this.deleteQueryParam('price_min');
        this.deleteQueryParam('price_max');
      }
      this.applyFiltering();
    },
    async applyFiltering() {
      this.$refs.loadingDialog.startLoading();
      if (this.isSortingOpen) this.closeSortingPanel();
      this.$emit("activateSkeletonLoader");
      await this.clearFiltersPaginationInfo();
      await this.clearProductsByFilter();
      await this.clearProductsPaginationInfo();
      await this.clearListOfProducts();
      await this.getProductsByFilter(this.categoryId);
      if (this.queryParams.toString().length) {
        amplitudeMarketplace({
          event_type: "Инструменты - Использование фильтра",
          extra_info: Object.fromEntries(this.queryParams),
          project_name: "Marketplace"
        });
      }
      this.$emit("deactivateSkeletonLoader");
      this.$refs.loadingDialog.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.pos-rel {
  position: relative;
}
.header-right-icon {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #267CCC;
}
.confirm-filters-btn-container {
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.confirm-filters-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
</style>