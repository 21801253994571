<template>
  <div
    data-app
    class="cart-container"
    :class="{ 'grey-bg': cart.lines.length > 0 }"
  >
    <div :class="{ 'market-header-container': cart.lines.length > 0 }">
      <v-card flat class="market-header-card">
        <v-card-text
          class="d-flex align-items-center justify-center"
          :class="{ 'justify-between': cart.lines.length > 0 }"
        >
          <div class="cart-header-title">
            <span>Корзина</span>
          </div>
          <LimitTag v-if="cart.lines.length > 0"/>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="!isCartLoaded">
      <div v-for="(n, index) in 5" :key="n">
        <div class="skeleton-orders-container">
          <v-skeleton-loader
            type="text@2"
            class="skeleton-orders-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="avatar@5"
            class="skeleton-orders-images"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="heading, text"
            class="skeleton-orders-footer"
          ></v-skeleton-loader>
        </div>
        <hr v-if="index !== 2" class="market-divider" />
      </div>
    </div>
    <div v-else>
      <v-row no-gutters v-if="cart.lines.length === 0">
        <v-col cols="12" class="empty-cart">
          <v-icon size="56" color="#EF7F1A" class="empty-cart-icon">
            mdi-cart
          </v-icon>
          <h5 class="empty-cart-title">В корзине пока пусто</h5>
          <p class="empty-cart-text">Перейдите в каталог, чтобы добавить товар</p>
          <v-btn
            width="100%"
            height="56"
            color="#EF7F1A"
            class="go-to-market-btn"
            :to="{ name: 'Market', ...(limitType === 'office_supplies' && { query: { product_class: 'office_supplies' } }) }"
          >
            Перейти в каталог
          </v-btn>
        </v-col>
      </v-row>
      <template v-else-if="cart.lines.length > 0">
<!--        <v-card flat class="cart-info-card cart-price">-->
<!--          <span class="cart-info-text">Сумма покупки</span>-->
<!--          <span class="cart-info-text">-->
<!--            {{ parsedTotalCartPrice.toLocaleString("ru") }} ₸-->
<!--          </span>-->
<!--        </v-card>-->
<!--        <v-card flat class="cart-info-card cart-price">-->
<!--          <span class="cart-info-text">Остаток лимита</span>-->
<!--          <span-->
<!--            class="cart-info-text"-->
<!--            :class="{ 'negative-limit': limitAfterOrder < 0 }"-->
<!--          >-->
<!--            {{ limitAfterOrder.toLocaleString("ru") }}-->
<!--            {{ limit ? ' ₸' : '' }}-->
<!--          </span>-->
<!--        </v-card>-->
        <p class="cart-sorted-hint">Информация для доставки</p>
        <v-card
          flat
          class="cart-order-card"
        >
          <div class="cart-order-text">
            <span>Адрес доставки</span>
            <span style="color: #f5463b">*</span>
          </div>
          <div class="cart-order-select" @click="openAddressDialog">
            <span v-if="selectedAddress">
              {{ `${selectedAddress.line4}, ${selectedAddress.line3}` }}
              <v-icon size="12" color="#EF7F1A">mdi-pencil-outline</v-icon>
            </span>
            <span v-else>Выберите адрес</span>
          </div>
        </v-card>
        <v-card
          flat
          class="cart-order-card mb-12"
        >
          <div class="cart-order-text">
            <span>МВЗ</span>
            <span style="color: #f5463b">*</span>
          </div>
          <div class="cart-order-select" @click="openMVZDialog">
            <span v-if="selectedMVZ">
              {{ selectedMVZ }}
              <v-icon size="12" color="#EF7F1A">mdi-pencil-outline</v-icon>
            </span>
            <span v-else>Выберите МВЗ</span>
          </div>
        </v-card>
        <div style="margin-bottom: 200px; margin-top: 1rem">
          <div class="cart__panel flex">
            <div @click="selectAll" >
              <svg v-if="selectedProducts.length === cart_products.length" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill="#EF7F1A"/>
                <path d="M15.2577 7.53033C15.5506 7.23744 15.5506 6.76256 15.2577 6.46967C14.9648 6.17678 14.4899 6.17678 14.197 6.46967L15.2577 7.53033ZM8.72734 13L8.19702 13.5303C8.48991 13.8232 8.96478 13.8232 9.25767 13.5303L8.72734 13ZM6.53032 9.7424C6.23742 9.44951 5.76255 9.44952 5.46966 9.74242C5.17677 10.0353 5.17678 10.5102 5.46968 10.8031L6.53032 9.7424ZM14.197 6.46967L8.19701 12.4697L9.25767 13.5303L15.2577 7.53033L14.197 6.46967ZM5.46968 10.8031L8.19702 13.5303L9.25766 12.4697L6.53032 9.7424L5.46968 10.8031Z" fill="white"/>
              </svg>
<!--              <img-->
<!--                v-if="selectedProducts.length === cart_products.length"-->
<!--                src="@/assets/marketplace/check.svg"-->
<!--                alt="check"-->
<!--                rel="preload"-->
<!--              />-->
              <div v-else class="order-unchecked" style="margin-left: 0"></div>
            </div>
            <p class="ml-16">Выбрать все</p>
            <span v-if="selectedProducts.length > 0" class="cart__panel__remove-all ml-auto" @click="confirmDeleteAll">
              Удалить выбранные
            </span>
          </div>
          <div
            v-for="item in cart.lines || []"
            :key="item.product_class_name"
          >
            <div class="cart__panel flex" @click="toggleClassShow(item.product_class_name)">
              <div @click.stop="setAllChecked(item.product_class_name)">
<!--                <img-->
<!--                  v-if="item.products.every(line => selectedProductsIds.includes(line.product.id))"-->
<!--                  src="@/assets/marketplace/check.svg"-->
<!--                  alt="check"-->
<!--                  rel="preload"-->
<!--                />-->
                <svg v-if="item.products.every(line => selectedProductsIds.includes(line.product.id))" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10" r="10" fill="#EF7F1A"/>
                  <path d="M15.2577 7.53033C15.5506 7.23744 15.5506 6.76256 15.2577 6.46967C14.9648 6.17678 14.4899 6.17678 14.197 6.46967L15.2577 7.53033ZM8.72734 13L8.19702 13.5303C8.48991 13.8232 8.96478 13.8232 9.25767 13.5303L8.72734 13ZM6.53032 9.7424C6.23742 9.44951 5.76255 9.44952 5.46966 9.74242C5.17677 10.0353 5.17678 10.5102 5.46968 10.8031L6.53032 9.7424ZM14.197 6.46967L8.19701 12.4697L9.25767 13.5303L15.2577 7.53033L14.197 6.46967ZM5.46968 10.8031L8.19702 13.5303L9.25766 12.4697L6.53032 9.7424L5.46968 10.8031Z" fill="white"/>
                </svg>

                <div v-else class="order-unchecked" style="margin-left: 0"></div>
              </div>
              <p class="ml-16" style="font-size: 18px">{{ getProductClassText(item.product_class_name) }}</p>
              <div class="cart__panel__tag">{{ item.products.length }} {{ getPluralText(item.products.length) }}</div>
              <img
                class="ml-auto cart__panel-icon"
                src="@/assets/marketplace/chevron_down.svg"
                alt="chevron"
                :class="[showedClasses.includes(item.product_class_name) && 'cart__panel-icon--is-open']"
              />
            </div>
            <div v-show="showedClasses.includes(item.product_class_name)">
              <v-card
                flat
                class="cart__panel__border"
                style="display: flex; align-items: center; padding: 1rem"
                v-for="line in item.products"
                :key="line.id"
              >
                <div class="mr-16" @click="selectProduct(line)">
                  <svg v-if="selectedProducts.includes(line)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" fill="#EF7F1A"/>
                    <path d="M15.2577 7.53033C15.5506 7.23744 15.5506 6.76256 15.2577 6.46967C14.9648 6.17678 14.4899 6.17678 14.197 6.46967L15.2577 7.53033ZM8.72734 13L8.19702 13.5303C8.48991 13.8232 8.96478 13.8232 9.25767 13.5303L8.72734 13ZM6.53032 9.7424C6.23742 9.44951 5.76255 9.44952 5.46966 9.74242C5.17677 10.0353 5.17678 10.5102 5.46968 10.8031L6.53032 9.7424ZM14.197 6.46967L8.19701 12.4697L9.25767 13.5303L15.2577 7.53033L14.197 6.46967ZM5.46968 10.8031L8.19702 13.5303L9.25766 12.4697L6.53032 9.7424L5.46968 10.8031Z" fill="white"/>
                  </svg>
                  <div v-else class="order-unchecked" style="margin-left: 0"></div>
                </div>
                <v-img
                  v-if="line.product.image"
                  :src="api_url + line.product.image"
                  height="90"
                  max-width="90"
                  contain
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/marketplace/no_image.svg"
                  height="90"
                  max-width="90"
                  contain
                ></v-img>
                <div class="cart-item-info">
                  <h5 class="cart-item-price">
                    {{ parseFloat(line.price_excl_tax).toLocaleString("ru") }} ₸
                  </h5>
                  <p class="cart-item-title">{{ line.product.title }}</p>
                  <div class="cart-item-actions">
                    <div class="in-cart-actions">
                      <button
                        type="button"
                        v-ripple="{ center: true }"
                        class="remove-from-cart-btn"
                        @click="checkForLastPiece(line)"
                      >
                        <span>-</span>
                      </button>
                      <span class="in-cart-amount"> {{ line.quantity }} шт. </span>
                      <button
                        type="button"
                        v-ripple="{ center: true }"
                        class="add-more-to-cart-btn"
                        @click="addToCartClicked(line.product)"
                      >
                        <span>+</span>
                      </button>
                    </div>
                    <v-btn
                      text
                      class="cart-item-remove"
                      @click="confirmDeletion(line)"
                    >
                      удалить
                    </v-btn>
                  </div>
                </div>
              </v-card>
              <div class="cart__info cart__panel__border">
                <div class="cart__info__total cart__panel__border">
                  <span>Сумма покупки</span>
                  {{ getSumOfSelectedProducts(item.product_class_name) }} ₸
                </div>
                <div class="cart__info__limit">
                  <span>Сумма остатка лимита</span>
                  <div v-if="item.product_class_name === 'garwin'" class="cart-limit-container">
                    <img src="@/assets/marketplace/limit_icon.svg" alt="limit" />
                    <span class="cart-limit-amount">
                      {{ limit ? `${calculateLimit.toLocaleString("ru")} ₸` : "Нет лимита" }}
                    </span>
                  </div>
                  <div v-else-if="item.product_class_name === 'office_supplies'" class="limit-tag is-stationery">
                    <span>
                      {{ stationeryLimit ? `${calculateStationeryLimit.toLocaleString("ru")} ₸` : "Нет лимита" }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-card class="cart-confirm-order">
          <p v-if="selectedProducts.length > 0" class="cart__total">
            <span>Выбрано: {{ selectedProducts.length }} {{ getPluralText(selectedProducts.length) }}</span>
            <span>{{ selectedProductsTotal.toLocaleString("ru") }} ₸</span>
          </p>
          <v-card-actions style="padding: 16px">
            <v-btn
              block
              color="#EF7F1A"
              class="cart-confirm-order-btn"
              :loading="isLoading"
              :disabled="!selectedAddress || !selectedMVZ || selectedProducts.length === 0"
              @click="confirmCheckout"
            >
              Оформить заказ
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </div>
    <v-dialog
      v-model="selectDialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card class="grey-bg">
        <div class="cart-select-header">
          <v-btn icon @click.stop="closeDialog" style="position: absolute">
            <v-icon size="36">mdi-chevron-left</v-icon>
          </v-btn>
          <span class="cart-select-title">
            {{ isAddressSelect ? "Выбор адреса" : "Выбор МВЗ" }}
          </span>
        </div>
        <p class="cart-sorted-hint">* Отсортировано по частоте использования</p>
        <v-row no-gutters v-if="selectAddress" class="white-bg">
          <template v-if="addressList && addressList.length > 0">
            <v-col
              cols="12"
              class="cart-select-item"
              v-for="item in addressList"
              :key="item.id"
              @click="onAddressSelect(item)"
            >
              <span>{{ `${item.line4}, ${item.line3}` }}</span>
              <v-icon color="#EF7F1A" v-if="selectedAddress.id === item.id">
                mdi-check-circle
              </v-icon>
            </v-col>
          </template>
          <template v-else>
            <v-col
              cols="12"
              class="cart-select-item"
              style="justify-content: center"
            >
              У вас нет адресов для доставки
            </v-col>
          </template>
        </v-row>
        <v-row no-gutters v-if="selectMVZ" class="white-bg">
          <template v-if="mvzList && mvzList.length > 0">
            <v-col
              cols="12"
              class="cart-select-item"
              v-for="mvz in mvzList"
              :key="mvz.mvz"
              @click="onMVZSelect(mvz.mvz)"
            >
              <span>{{ mvz.mvz }}</span>
              <v-icon color="#EF7F1A" v-if="selectedMVZ === mvz.mvz">
                mdi-check-circle
              </v-icon>
            </v-col>
          </template>
          <template v-else>
            <v-col
              cols="12"
              class="cart-select-item"
              style="justify-content: center"
            >
              У вас нет МВЗ
            </v-col>
          </template>
        </v-row>
      </v-card>
    </v-dialog>
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="!isDeleteAll ? deleteProduct() : deleteSelectedProducts()"
    />
    <LoadingDialog ref="loadingDialog" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AlertDialog from "@/components/marketplace/alert_dialog.vue";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";
import LimitTag from "@/components/marketplace/LimitTag.vue"
import LoadingDialog from "@/components/marketplace/loading_dialog.vue"

export default {
  data: () => ({
    isCartLoaded: false,
    selectDialog: false,
    isAddressSelect: undefined,
    selectAddress: false,
    selectMVZ: false,
    selectedAddress: "",
    selectedMVZ: "",
    deletingLineId: undefined,
    alertInfo: {},
    alertDialog: false,
    lines: [],
    selectedProducts: [],
    showedClasses: [],
    isLoading: false,
    isDeleteAll: false,
  }),
  components: {
    LoadingDialog,
    LimitTag,
    AlertDialog,
  },
  watch: {
    cart_products(newValue) {
      this.selectedProducts = this.selectedProducts
        .filter(product => !!newValue.find(new_product => new_product.id === product.id))
        .map(product => newValue.find(new_product => new_product.id === product.id) ?? product)
    }
  },
  computed: {
    ...mapState("marketplace", [
      "marketUser",
      "api_url",
      "limit",
      "stationeryLimit",
      "limitType",
      "addressList",
      "mvzList",
      "cart",
      "cart_products",
      "orders",
    ]),
    ...mapGetters("marketplace", ["cart_price_total"]),
    parsedTotalCartPrice() {
      return parseFloat(this.cart_price_total);
    },
    limitAfterOrder() {
      if (this.limit) return this.limit - this.parsedTotalCartPrice;
      else return 'Нет лимита';
    },
    selectedProductsIds() {
      return this.selectedProducts.map(line => line?.product.id)
    },
    selectedProductsTotal() {
      return this.selectedProducts.reduce((acc, product) => {
        return acc + parseFloat(product.price_excl_tax)
      }, 0)
    },
    calculateLimit() {
      if(!this.limit) return 0
      const total = this.getSumOfSelectedProducts('garwin')
      return this.limit - total
    },
    calculateStationeryLimit() {
      if(!this.stationeryLimit) return 0
      const total = this.getSumOfSelectedProducts('office_supplies')
      return this.stationeryLimit - total
    }
  },
  async created() {
    await this.getUserInfo();
    await this.getUserCart();
    this.isCartLoaded = true;
    amplitudeMarketplace({
      event_type: "Инструменты - Переход во вкладку Корзина",
      extra_info: null,
      project_name: "Marketplace"
    });
  },
  mounted() {
    backNavigateQueue.set(this.backNavigate);
  },
  methods: {
    ...mapActions("marketplace", [
      "getUserInfo",
      "getUserCart",
      "addToCart",
      "removeFromCart",
      "deleteProductFromCart",
      "checkoutOrder",
      "clearSubcategory",
      "clearSubSubcategory",
      "clearSubSubSubcategory",
      "clearListOfProducts",
      "clearProduct",
    ]),
    backNavigate() {
      this.$router.go(-1);
    },
    getProductClassText(name) {
      return {
        'office_supplies': 'Канцтовары'
      }[name] || 'Инструменты'
    },
    getPluralText(count) {
      if([2,3,4].includes(count)) return 'товара'
      else if(count === 1) return 'товар'

      return 'товаров'
    },
    toggleClassShow(class_name) {
      const index = this.showedClasses.indexOf(class_name);
      if (index === -1) {
        this.showedClasses = [...this.showedClasses, class_name]
      } else {
        this.showedClasses.splice(index, 1)
      }
    },
    getSumOfProducts(lines = []) {
      if(!lines) return 0
      return lines.reduce((acc, product) => {
        return acc + parseFloat(product.price_excl_tax)
      }, 0)
    },
    getSumOfSelectedProducts(class_name) {
      const productClassProducts = this.cart?.lines?.find(item => item.product_class_name === class_name)?.products || []
      const total = this.getSumOfProducts(productClassProducts.filter(product => this.selectedProducts.map(product => product.id).includes(product.id)))
      return total
    },
    selectProduct(product) {
      const index = this.selectedProducts.indexOf(product)
      if(index === -1) {
        this.selectedProducts = [...this.selectedProducts, product]
      } else {
        this.selectedProducts.splice(index, 1)
      }
    },
    setAllChecked(name) {
      const products = (this.cart.lines || []).find(item => item.product_class_name === name)?.products || []

      if(products.every(product => this.selectedProductsIds.includes(product?.product?.id))) {
        for (const product of products) {
          this.selectProduct(product)
        }
      } else {
        for (const product of products) {
          if(!this.selectedProductsIds.includes(product?.product?.id)) this.selectProduct(product)
        }
      }
    },
    selectAll() {
      this.selectedProducts = this.selectedProducts.length === this.cart_products.length ? [] : [...this.cart_products]
    },
    addToCartClicked(product) {
      this.addToCart({ id: product.id });
      amplitudeMarketplace({
        event_type: "Инструменты - Добавление товара в Корзину",
        extra_info: product.title,
        project_name: "Marketplace"
      });
    },
    openAddressDialog() {
      this.selectDialog = true;
      this.isAddressSelect = true;
      this.selectAddress = true;
    },
    openMVZDialog() {
      this.selectDialog = true;
      this.isAddressSelect = false;
      this.selectMVZ = true;
    },
    closeDialog() {
      this.selectDialog = false;
      this.selectAddress = false;
      this.selectMVZ = false;
    },
    onAddressSelect(item) {
      this.selectedAddress = item;
      this.closeDialog();
      this.addressNotSelected = false;
    },
    onMVZSelect(item) {
      this.selectedMVZ = item;
      this.closeDialog();
    },
    checkForLastPiece(line) {
      if (line.quantity === 1) {
        this.confirmDeletion(line);
      } else {
        this.removeFromCart(line.product.id);
      }
    },
    confirmDeletion(payload) {
      this.isDeleteAll = false
      this.deletingLine = payload;
      this.alertInfo = {
        alert_title: "Удаление из корзины",
        alert_message: payload.product.title,
        cancel_btn_text: "Отмена",
        confirm_btn_text: "Удалить",
      };
      this.alertDialog = true;
    },
    confirmDeleteAll() {
      this.isDeleteAll = true
      this.alertInfo = {
        alert_title: "Удаление из корзины",
        alert_message: 'Вы действительно хотите удалить выбранные товары?',
        cancel_btn_text: "Отмена",
        confirm_btn_text: "Удалить",
      };
      this.alertDialog = true;
    },
    closeAlert() {
      this.alertDialog = false;
      this.alertInfo = {};
      this.isDeleteAll = false
    },
    deleteProduct() {
      this.deleteProductFromCart({ lineId: this.deletingLine.id });
      this.deletingLine = undefined;
      this.closeAlert();
    },
    async deleteSelectedProducts() {
      this.$refs.loadingDialog.startLoading();
      await Promise.allSettled(this.selectedProducts.map(line => this.deleteProductFromCart({ lineId: line.id })))
      this.$refs.loadingDialog.stopLoading();
      this.closeAlert()
    },
    async confirmCheckout() {
      this.isLoading = true
      try {
        let res = await this.checkoutOrder({
          shipping_method_code: "free-shipping",
          shipping_address: {
            country: "KZ",
            first_name: this.marketUser.first_name,
            last_name: this.marketUser.last_name,
            line1: this.selectedAddress.line1,
            line2: this.selectedAddress.line2,
            line3: this.selectedAddress.line3,
            line4: this.selectedAddress.line4,
            phone_number: this.marketUser.phone_number,
          },
          mvz: this.selectedMVZ,
          selected_products: this.selectedProductsIds
        })
        amplitudeMarketplace({
          event_type: "Инструменты - Заказ создан",
          extra_info: null,
          project_name: "Marketplace"
        });
        this.$router.push({ name: "Orders", params: { id: res.pk } });
        this.getUserCart();
      } catch (err) {
        let errorMessage = err.response.data.message;
        if (errorMessage) {
          this.alertInfo = {
            alert_title: "Превышен лимит",
            alert_message: errorMessage,
            cancel_btn_text: "Ок",
          };
          this.alertDialog = true;
          amplitudeMarketplace({
            event_type: "Инструменты - Заказ не создан",
            extra_info: "Не хватает лимита",
            project_name: "Marketplace"
          });
        }
      } finally {
        this.isLoading = false
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.clearSubcategory();
    this.clearSubSubcategory();
    this.clearSubSubSubcategory();
    this.clearListOfProducts();
    this.clearProduct();
    next();
  },
};
</script>

<style lang="scss">
.cart-sorted-hint {
  color: #8E8E93;
  margin: 12px 10px;
}

.cart__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-inline: 1rem;
  font-weight: 500;
}

.cart__info {
  padding: 1rem;
  background-color: #FFF;
  font-weight: 500;

  &__total {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  &__limit {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
}

.cart__panel {
  border-bottom: 1px solid #E0E4E9;
  background-color: #FFF;
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding-inline: 1rem;

  p {
    font-weight: 500;
  }

  &-icon {
    transition: all .4s ease;

    &--is-open {
      transform: rotate(-180deg);
    }
  }

  &__border {
    border-bottom: 1px solid #E0E4E9 !important;
  }

  &__tag {
    font-size: 14px;
    font-weight: 500;
    color: #EF7F1A;
    background-color: rgba(239, 127, 26, 0.12);
    border-radius: 8px;
    margin-left: 8px;
    padding: 4px .5rem
  }

  &__remove-all {
    color: #3376F6;
    font-weight: 500;
  }
}
</style>
