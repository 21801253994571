<template>
  <main
    data-app
    v-if="orderById"
    class="layout__body no-padding"
    :class="{ 'prevent-scrolling': isFeedbackOpen }"
  >
    <header class="market-header-container">
      <div class="market-header-card">
        <div class="market-header-title">
          <div class="cart-header-title" style="margin: 0 auto">
            <span v-if="is_cancelling"> Отмена позиций </span>
            <span v-else-if="is_accepting"> Выбор доставленного товара </span>
            <span v-else> Заказ #{{ orderById.number }} </span>
            <button
              v-if="
                isLinesCancellable &&
                !is_accepting &&
                statuses.order__active.includes(orderById.status)
              "
              type="button"
              class="header-right-icon"
              @click="toggleCancellingOrder"
            >
              <img
                src="@/assets/marketplace/cancel_icon.svg"
                alt="edit"
                v-if="is_cancelling"
              />
              <img src="@/assets/marketplace/edit_icon.svg" alt="edit" v-else />
            </button>
            <button
              v-if="is_accepting && !is_cancelling"
              type="button"
              class="header-right-icon"
              @click="toggleAcceptingOrder"
            >
              <img src="@/assets/marketplace/cancel_icon.svg" alt="edit" />
            </button>
          </div>
        </div>
      </div>
    </header>
    <div
      class="market-container grey-bg"
      :class="{ 'mb-160': is_cancelling || isLinesAcceptable }"
    >
      <div class="order-info">
        <div class="order-price-info">
          <span class="order-price-title">Итоговая сумма</span>
          <span class="order-price">
            {{ parseFloat(orderById.total_excl_tax).toLocaleString("ru") }} ₸
          </span>
        </div>
        <div class="d-flex">
          <div>
            <div class="order-status-info" style="margin-bottom: 12px">
              <span class="order-status-title">Статус</span>
              <span
                class="order-status orange-status"
                :class="{
                  'red-status': statuses.order__cancelled.includes(
                    orderById.status
                  ),
                  'green-status': statuses.order__completed.includes(
                    orderById.status
                  ),
                }"
              >
                {{ orderById.status }}
              </span>
            </div>
            <div class="order-status-info" style="margin-bottom: 12px">
              <span class="order-status-title">Согласующий</span>
              <span class="order-status">
                {{ marketUser.approver.full_name }}
              </span>
            </div>
          </div>
          <div>
            <div class="order-product-amount-info" style="margin-bottom: 12px">
              <span class="order-product-amount-title">Количество товаров</span>
              <span class="order-product-amount">
                {{ orderById.lines.length }}
              </span>
            </div>
            <div class="order-product-amount-info" style="margin-bottom: 12px">
              <span class="order-product-amount-title">
                Дата создания заказа
              </span>
              <span class="order-product-amount">
                {{ formattedCreatedAtDate + " " + formattedCreatedAtTime }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div
            v-if="orderById.notes && orderById.notes.length > 0"
            class="order-status-info"
          >
            <span class="order-status-title">Комментарий</span>
            <span
              v-for="(note, index) in orderById.notes"
              :key="index"
              class="order-status"
            >
              {{ note }}
            </span>
          </div>
          <div v-if="orderById.delegated_to">
            <div
              v-if="marketUser.full_name === orderById.delegated_to.full_name"
              class="order-status-info"
            >
              <span class="order-status-title">
                Делегирован от:
              </span>
              <span class="order-status">
                {{ orderById.owner.full_name }}
              </span>
            </div>
            <div v-else class="order-status-info">
              <span class="order-status-title">
                Делегирован:
              </span>
              <span class="order-status">
                {{ orderById.delegated_to.full_name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="order-products-container">
        <div
          v-if="is_cancelling || is_accepting"
          class="d-flex align-items-center"
          :class="{ 'added-to-cancel': isAllItemsCancelling }"
          @click="addAllToQueue"
        >
          <img
            v-if="isAllItemsCancelling"
            src="@/assets/marketplace/check.svg"
            alt="check"
            style="margin-left: 16px"
          />
          <div v-else class="order-unchecked"></div>
          <span style="padding: 16px">Выделить все</span>
        </div>
        <div
          v-for="(line, index) in filteredLines"
          :key="line.id"
          :class="{
            'added-to-cancel': cancelQueue.includes(line.id),
            'cancelled-line':
              is_cancelling || is_accepting
                ? !lineIds.includes(line.id)
                : false,
          }"
          @click="checkStatusAndAddToCancelQueue(line.id, line.status)"
        >
          <div class="d-flex align-items-center">
            <div v-if="is_cancelling || is_accepting">
              <div
                v-if="!cancelQueue.includes(line.id)"
                class="order-unchecked"
              ></div>
              <img
                v-show="cancelQueue.includes(line.id)"
                src="@/assets/marketplace/check.svg"
                alt="check"
                style="margin-left: 16px"
              />
            </div>
            <div class="order-products">
              <div class="order-product-status-and-delivery">
                <div
                  class="order-product-status orange-status"
                  :class="{
                    'red-status': statuses.line__cancelled.includes(line.status),
                    'green-status': statuses.line__completed.includes(line.status),
                  }"
                >
                  <span>{{ line.status }}</span>
                </div>
                <div
                  v-if="line.preliminary_delivery_date"
                  class="order-product-delivery"
                >
                  <img
                    src="@/assets/marketplace/delivery_icon.svg"
                    alt="devilery"
                  />
                  <span>
                    {{
                      new Date(
                        line.preliminary_delivery_date
                      ).toLocaleDateString("ru", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })
                    }}
                  </span>
                </div>
              </div>
              <div class="order-photo-container">
                <img
                  v-if="line.product.image"
                  :src="api_url + line.product.image"
                  alt="product"
                  style="max-width: 100%; max-height: 100%"
                />
                <img
                  v-else
                  src="@/assets/marketplace/no_image.svg"
                  alt="no_image"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
              <span class="order-product-vendor-code">
                Артикул: {{ line.stockrecord.partner_sku }}
              </span>
              <span class="order-product-title">{{ line.product.title }}</span>
              <div class="d-flex align-items-center">
                <span class="order-product-price">
                  {{ parseFloat(line.price_excl_tax).toLocaleString("ru") }} ₸
                </span>
                <span class="order-product-quantity">
                  {{ line.quantity }} шт.
                </span>
              </div>
              <button
                v-if="line.status === 'Получено' && !line.is_reviewed"
                type="button"
                class="feedback-btn"
                :disabled="is_cancelling || is_accepting"
                @click="openFeedback(line)"
              >
                Написать отзыв
              </button>
            </div>
          </div>
          <hr
            v-if="index !== filteredLines.length - 1"
            class="market-divider"
          />
        </div>
      </div>
      <div v-if="is_cancelling" class="cancel-order-container">
        <button
          type="button"
          class="cancel-order-btn"
          :disabled="cancelQueue && cancelQueue.length < 1"
          @click="confirmCancelLines(cancelQueue)"
        >
          <span v-if="cancelQueue.length > 1">Отменить позиции</span>
          <span v-else>Отменить позицию</span>
        </button>
      </div>
      <div
        v-if="
          (canBeDelegated && !is_accepting && !is_cancelling) ||
          (isLinesAcceptable && !is_accepting && !is_cancelling)
        "
        class="accept-order-container"
        :class="{ 'two-buttons': canBeDelegated }"
      >
        <button
          v-if="canBeDelegated"
          type="button"
          class="accept-order-btn"
          :class="{ 'half-width-button': canBeDelegated && isLinesAcceptable }"
          @click="delegateDialog = true"
        >
          <span>Делегировать</span>
        </button>
        <button
          v-if="isLinesAcceptable && !is_accepting && !is_cancelling"
          type="button"
          class="accept-order-btn"
          :class="{ 'half-width-button': canBeDelegated }"
          @click="toggleAcceptingOrder"
        >
          <span>Приёмка товара</span>
        </button>
      </div>
      <div v-if="is_accepting" class="accept-order-actions-container">
        <button
          type="button"
          class="reject-line-btn"
          :disabled="cancelQueue.length === 0"
          @click="confirmRejectLines"
        >
          <span>Отклонить</span>
        </button>
        <button
          type="button"
          class="accept-line-btn"
          :disabled="cancelQueue.length === 0"
          @click="confirmAcceptLines"
        >
          <span>Принять</span>
        </button>
      </div>
    </div>
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertDialog"
      @emitCloseAlert="closeAlert"
    />
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertCancelDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="cancelLines"
    />
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertRejectDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="rejectLines"
    />
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertAcceptDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="acceptLines"
    />
    <DelegateDialog
      :delegateDialog="delegateDialog"
      :orderId="id"
      @closeDelegateDialog="delegateDialog = false"
      @updateOrder="getOrder"
    />
    <Feedback
      ref="feedbackDialog"
      :lineInfo="lineInfo"
      :isFeedbackOpen="isFeedbackOpen"
      @closeFeedback="closeFeedback"
      @startLoading="startLoading"
      @stopLoading="stopLoading"
      @feedbackSuccess="feedbackSuccess"
      @feedbackError="feedbackError"
    />
    <LoadingDialog ref="loadingDialog" />
  </main>
</template>

<script>
import AlertDialog from "@/components/marketplace/alert_dialog.vue";
import DelegateDialog from "@/components/marketplace/delegate_dialog.vue";
import Feedback from "@/components/marketplace/feedback.vue";
import LoadingDialog from "@/components/marketplace/loading_dialog.vue";
import { mapActions, mapState } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";

export default {
  props: {
    id: [Number, String],
  },
  data: () => ({
    is_cancelling: false,
    is_accepting: false,
    delegateDialog: false,
    lineIds: [],
    alertInfo: {},
    alertDialog: false,
    alertCancelDialog: false,
    alertRejectDialog: false,
    alertAcceptDialog: false,
    isFeedbackOpen: false,
    lineInfo: undefined,
  }),
  components: {
    AlertDialog,
    DelegateDialog,
    Feedback,
    LoadingDialog,
  },
  computed: {
    ...mapState("marketplace", [
      "api_url",
      "marketUser",
      "statuses",
      "orderById",
      "cancelQueue",
    ]),
    formattedCreatedAtDate() {
      return new Date(this.orderById.date_placed).toLocaleDateString("ru", {
        day: "numeric",
        month: "2-digit",
        year: "2-digit",
      });
    },
    formattedCreatedAtTime() {
      return new Date(this.orderById.date_placed).toLocaleTimeString("ru", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    cancellableLines() {
      return this.orderById.lines.filter((line) =>
        this.statuses.line__cancellable.find(
          (status) => status === line.status
        )
      );
    },
    notCancellableLines() {
      return this.orderById.lines.filter(
        (line) =>
          !this.statuses.line__cancellable.find(
            (status) => status === line.status
          )
      );
    },
    isLinesCancellable() {
      return this.cancellableLines?.length ? true : false;
    },
    cancelledLines() {
      return this.orderById.lines.filter((line) =>
        this.statuses.line__cancelled.find(
          (status) => status === line.status
        )
      );
    },
    notCancelledLines() {
      return this.orderById.lines.filter(
        (line) =>
          !this.statuses.line__cancelled.find(
            (status) => status === line.status
          )
      );
    },
    ownerAcceptableLines() {
      return this.orderById.lines.filter((line) =>
        this.statuses.line__acceptable_by_owner.find(
          (status) => status === line.status
        )
      );
    },
    ownerNotAcceptableLines() {
      return this.orderById.lines.filter(
        (line) =>
          !this.statuses.line__acceptable_by_owner.find(
            (status) => status === line.status
          )
      );
    },
    isLinesAcceptable() {
      return this.ownerAcceptableLines?.length ? true : false;
    },
    filteredLines() {
      if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_cancelling
      ) {
        return this.cancellableLines.concat(this.notCancellableLines);
      } else if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_accepting
      ) {
        return this.ownerAcceptableLines.concat(this.ownerNotAcceptableLines);
      } else if (this.isLinesCancellable) {
        return this.notCancelledLines.concat(this.cancelledLines);
      } else if (this.isLinesAcceptable) {
        return this.notCancelledLines.concat(this.cancelledLines);
      } else {
        return this.orderById.lines;
      }
    },
    isAllItemsCancelling() {
      if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_cancelling &&
        this.cancellableLines.length === this.cancelQueue.length &&
        this.cancellableLines.length !== 0
      )
        return true;
      else if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_accepting &&
        this.ownerAcceptableLines.length === this.cancelQueue.length &&
        this.ownerAcceptableLines.length !== 0
      )
        return true;
      else if (
        this.isLinesCancellable &&
        this.notCancelledLines.length === this.cancelQueue.length &&
        this.notCancelledLines.length !== 0
      )
        return true;
      else if (
        this.isLinesAcceptable &&
        this.ownerAcceptableLines.length === this.cancelQueue.length &&
        this.ownerAcceptableLines.length !== 0
      )
        return true;
      else return false;
    },
    canBeDelegated() {
      if (this.orderById.status === 'Активный' && !this.orderById.delegated_to) {
        return true;
      } else return false;
    },
  },
  created() {
    this.getOrder();
    amplitudeMarketplace({
      event_type: "Инструменты - Переход в карточку заказа",
      extra_info: null,
      project_name: "Marketplace"
    });
    let routerQuery = this.$router.currentRoute.query;
    if (routerQuery.event_type === 'qr_code_scanned') {
      amplitudeMarketplace({
        event_type: "Инструменты - Сканирование QR кода",
        extra_info: null,
        project_name: "Marketplace"
      });
    } else if (routerQuery.event_type === 'canceled_by_customer') {
      amplitudeMarketplace({
        event_type: "Инструменты - Переход по PUSH уведомлению",
        extra_info: "Отменено заказчиком",
        project_name: "Marketplace"
      });
    } else if (routerQuery.event_type === 'canceled_by_supplier') {
      amplitudeMarketplace({
        event_type: "Инструменты - Переход по PUSH уведомлению",
        extra_info: "Отменено поставщиком",
        project_name: "Marketplace"
      });
    } else if (routerQuery.event_type === 'not_approved_by_manager') {
      amplitudeMarketplace({
        event_type: "Инструменты - Переход по PUSH уведомлению",
        extra_info: "Не согласовано руководителем",
        project_name: "Marketplace"
      });
    } else if (routerQuery.event_type === 'on_the_way') {
      amplitudeMarketplace({
        event_type: "Инструменты - Переход по PUSH уведомлению",
        extra_info: "В пути",
        project_name: "Marketplace"
      });
    } else if (routerQuery.event_type === 'delivered') {
      amplitudeMarketplace({
        event_type: "Инструменты - Переход по PUSH уведомлению",
        extra_info: "Доставлено",
        project_name: "Marketplace"
      });
    } else if (routerQuery.event_type === 'not_accepted') {
      amplitudeMarketplace({
        event_type: "Инструменты - Переход по PUSH уведомлению",
        extra_info: "Не принято",
        project_name: "Marketplace"
      });
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
  },
  methods: {
    ...mapActions("marketplace", [
      "getOrderById",
      "clearOrderById",
      "addToCancelQueue",
      "addAllToCancelQueue",
      "clearCancelQueue",
      "cancelOrderLines",
      "acceptOrderedLines",
      "rejectOrderedLines",
    ]),
    getOrder() {
      this.getOrderById(this.id);
    },
    async getLineIds() {
      this.lineIds = [];
      if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_cancelling
      ) {
        this.cancellableLines.forEach((line) => {
          this.lineIds = [...this.lineIds, line.id];
        });
      } else if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_accepting
      ) {
        this.ownerAcceptableLines.forEach((line) => {
          this.lineIds = [...this.lineIds, line.id];
        });
      } else if (this.isLinesCancellable) {
        this.notCancelledLines.forEach((line) => {
          this.lineIds = [...this.lineIds, line.id];
        });
      } else if (this.isLinesAcceptable) {
        this.ownerAcceptableLines.forEach((line) => {
          this.lineIds = [...this.lineIds, line.id];
        });
      }
    },
    checkStatusAndAddToCancelQueue(lineId, lineStatus) {
      if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_cancelling &&
        this.statuses.line__cancellable.includes(lineStatus)
      ) {
        this.addToCancelQueue({
          is_editing: this.is_cancelling,
          lineId,
        });
      } else if (
        this.isLinesCancellable &&
        this.isLinesAcceptable &&
        this.is_accepting &&
        this.statuses.line__acceptable_by_owner.includes(lineStatus)
      ) {
        this.addToCancelQueue({
          is_editing: this.is_accepting,
          lineId,
        });
      } else if (
        this.isLinesCancellable &&
        this.statuses.line__cancellable.includes(lineStatus)
      ) {
        this.addToCancelQueue({
          is_editing: this.is_cancelling,
          lineId,
        });
      } else if (
        this.isLinesAcceptable &&
        this.statuses.line__acceptable_by_owner.includes(lineStatus)
      ) {
        this.addToCancelQueue({
          is_editing: this.is_accepting,
          lineId,
        });
      }
    },
    addAllToQueue() {
      if (this.is_cancelling) {
        this.addAllToCancelQueue({
          is_editing: this.is_cancelling,
          lineIds: this.lineIds,
        });
      } else if (this.is_accepting) {
        this.addAllToCancelQueue({
          is_editing: this.is_accepting,
          lineIds: this.lineIds,
        });
      }
    },
    confirmCancelLines() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: "Вы точно хотите отменить заказ выделенных позиций?",
        cancel_btn_text: "Нет",
        confirm_btn_text: "Да",
      };
      this.alertCancelDialog = true;
    },
    confirmAcceptLines() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: "Вы точно хотите принять выделенные позиции?",
        cancel_btn_text: "Нет",
        confirm_btn_text: "Да",
      };
      this.alertAcceptDialog = true;
    },
    confirmRejectLines() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: "Вы точно хотите отклонить выделенные позиции?",
        cancel_btn_text: "Нет",
        confirm_btn_text: "Да",
      };
      this.alertRejectDialog = true;
    },
    closeAlert() {
      if (this.alertDialog) this.alertDialog = false;
      if (this.alertCancelDialog) this.alertCancelDialog = false;
      if (this.alertRejectDialog) this.alertRejectDialog = false;
      if (this.alertAcceptDialog) this.alertAcceptDialog = false;
      this.alertInfo = {};
    },
    startLoading() {
      this.$refs.loadingDialog.startLoading();
    },
    stopLoading() {
      this.$refs.loadingDialog.stopLoading();
    },
    async cancelLines() {
      this.alertCancelDialog = false;
      this.alertInfo = {};
      try {
        this.startLoading();
        await this.cancelOrderLines(this.id);
      } catch (err) {
        let errMessage = err.response.data.message;
        if (errMessage.startsWith("preliminary_delivery_date")) {
          this.alertInfo = {
            alert_title: "Действие отклонено",
            alert_message:
              "К сожалению, вы не можете отменить заказ позиций, до доставки которых осталось менее 24-х часов",
            cancel_btn_text: "Ок",
          };
          this.alertDialog = true;
        } else if (
          errMessage.startsWith("'Отменено заказчиком'") ||
          errMessage.startsWith("'Отменено поставщиком'")
        ) {
          this.alertInfo = {
            alert_title: "Действие отклонено",
            alert_message:
              "К сожалению, вы не можете отменить заказ позиции с текущим статусом. " +
              errMessage,
            cancel_btn_text: "Ок",
          };
          this.alertDialog = true;
        } else {
          this.alertInfo = {
            alert_title: "Действие отклонено",
            alert_message: errMessage,
            cancel_btn_text: "Ок",
          };
          this.alertDialog = true;
        }
      } finally {
        await this.getOrder();
        this.toggleCancellingOrder();
        this.stopLoading();
      }
    },
    async rejectLines() {
      this.alertRejectDialog = false;
      this.alertInfo = {};
      this.startLoading();
      await this.rejectOrderedLines({
        orderNumber: this.orderById.number,
        linesArray: this.cancelQueue,
      });
      await this.getOrder();
      this.toggleAcceptingOrder();
      this.stopLoading();
    },
    async acceptLines() {
      this.alertAcceptDialog = false;
      this.alertInfo = {};
      this.startLoading();
      await this.acceptOrderedLines({
        orderNumber: this.orderById.number,
        linesArray: this.cancelQueue,
      });
      await this.getOrder();
      this.toggleAcceptingOrder();
      this.stopLoading();
    },
    backToOrders() {
      this.$router.replace({ name: "Orders" });
    },
    toggleCancellingOrder() {
      this.is_cancelling = !this.is_cancelling;
      if (this.is_cancelling) this.getLineIds();
      if (!this.is_cancelling) {
        this.clearCancelQueue("");
      }
    },
    toggleAcceptingOrder() {
      this.is_accepting = !this.is_accepting;
      if (this.is_accepting) this.getLineIds();
      if (!this.is_accepting) {
        this.clearCancelQueue("");
      }
    },
    openFeedback(line) {
      this.lineInfo = line;
      this.isFeedbackOpen = true;
      amplitudeMarketplace({
        event_type: "Инструменты - Отзыв",
        extra_info: null,
        project_name: "Marketplace"
      });
    },
    closeFeedback() {
      this.isFeedbackOpen = false;
      setTimeout(() => {
        this.lineInfo = undefined;
        this.$refs.feedbackDialog.clearFeedback();
      }, 300);
    },
    async feedbackSuccess() {
      await this.getOrder();
      this.alertInfo = {
        alert_title: "Спасибо за отзыв",
        alert_message: "Отзыв появится на странице товара после проверки",
        cancel_btn_text: "Ок",
      };
      this.alertDialog = true;
    },
    feedbackError(errMessage) {
      if (errMessage) {
        this.alertInfo = {
          alert_title: "Действие отклонено",
          alert_message: errMessage,
          cancel_btn_text: "Ок",
        };
      } else {
        this.alertInfo = {
          alert_title: "Действие отклонено",
          alert_message: "Ошибка при отправке отзыва",
          cancel_btn_text: "Ок",
        };
      }
      this.alertDialog = true;
    },
    handleBackNavigation() {
      this.isFeedbackOpen ? this.closeFeedback() : this.backToOrders();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.is_cancelling = false;
    this.is_accepting = false;
    this.clearCancelQueue("");
    this.lineIds = [];
    this.clearOrderById();
    next();
  },
};
</script>

<style lang="scss" scoped>
.mb-160 {
  margin-bottom: 160px;
}
.added-to-cancel {
  border-radius: 16px;
  background: rgba(239, 127, 26, 0.08);
}
.cancelled-line {
  opacity: 0.5;
  border-radius: 16px;
  background: #f9f9f9;
}
.cancel-order-container {
  margin-bottom: 64px; //64px for footer
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
}
.cancel-order-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;
}
.cancel-order-btn > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.cancel-order-btn:disabled {
  background: rgba(239, 127, 26, 0.08);
}
.cancel-order-btn:disabled > span {
  color: #8e8e93;
}
.accept-order-container {
  margin-bottom: 64px; //64px for footer
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
}
.accept-order-actions-container {
  margin-bottom: 64px; //64px for footer
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
}
.accept-order-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;
}
.accept-order-btn > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.reject-line-btn,
.accept-line-btn {
  padding: 16px;
  height: 56px;
  width: calc(50% - 4px);
  border-radius: 16px;
}
.reject-line-btn > span,
.accept-line-btn > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.reject-line-btn {
  background: #ef7f1a;
}
.accept-line-btn {
  background: #90bc34;
}
.reject-line-btn:disabled {
  background: rgba(239, 127, 26, 0.08);
}
.accept-line-btn:disabled {
  background: rgba(144, 188, 52, 0.08);
}
.reject-line-btn:disabled > span,
.accept-line-btn:disabled > span {
  color: #8e8e93;
}
.two-buttons {
  display: flex;
  justify-content: space-between;
}
.half-width-button {
  width: calc(50% - 4px);
}
.feedback-btn {
  margin-top: 12px;
  padding: 12px 32px;
  width: 100%;
  height: 48px;
  background: #ef7f1a;
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.feedback-btn:disabled {
  color: #8e8e93;
  background: rgba(239, 127, 26, 0.08);
}
</style>
