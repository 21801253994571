var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cart-container",class:{ 'grey-bg': _vm.isOrdersAvailable }},[_c('div',{class:{ 'market-header-container': _vm.isOrdersAvailable }},[_c('v-card',{staticClass:"market-header-card",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex align-items-center justify-center"},[_c('div',{staticClass:"cart-header-title"},[_c('span',[_vm._v("Заказы на согласование")])])])],1)],1),(!_vm.isApproveOrdersLoaded)?_c('div',_vm._l((5),function(n,index){return _c('div',{key:n},[_c('div',{staticClass:"skeleton-orders-container"},[_c('v-skeleton-loader',{staticClass:"skeleton-orders-heading",attrs:{"type":"text@2"}}),_c('v-skeleton-loader',{staticClass:"skeleton-orders-images",attrs:{"type":"avatar@3"}}),_c('v-skeleton-loader',{staticClass:"skeleton-orders-footer",attrs:{"type":"heading, text"}})],1),(index !== 4)?_c('hr',{staticClass:"market-divider"}):_vm._e()])}),0):_c('div',[(!_vm.isOrdersAvailable)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"empty-cart",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"empty-cart-icon",attrs:{"size":"56","color":"#EF7F1A"}},[_vm._v(" mdi-clock-outline ")]),_c('h5',{staticClass:"empty-cart-title"},[_vm._v("Нет заказов на согласование")])],1)],1):_vm._e(),_c('div',{staticClass:"market-container",class:{ 'small-margin': !_vm.isLastPage }},_vm._l((_vm.approveOrders),function(order,index){return _c('router-link',{key:order.pk,attrs:{"to":{ name: 'ApproveById', params: { id: order.pk } }}},[_c('div',{staticClass:"order-card"},[_c('div',{staticClass:"order-title"},[_c('span',{staticClass:"order-status",class:{
                'red-status':
                  order.status === 'Не согласовано руководителем' ||
                  order.status === 'Отменено заказчиком' ||
                  order.status === 'Отменено поставщиком',
                'green-status':
                  order.status === 'Выполнено',
              }},[_vm._v(" "+_vm._s(order.status)+" ")]),_c('span',{staticClass:"order-number"},[_vm._v("# "+_vm._s(order.number))])]),_c('div',{staticClass:"order-photos"},_vm._l((order.lines),function(line){return _c('div',{key:line.id,staticClass:"order-photo-container"},[(line.product.image)?_c('img',{staticStyle:{"max-width":"100%","max-height":"100%"},attrs:{"src":_vm.api_url + line.product.image,"alt":"product"}}):_c('img',{staticStyle:{"max-width":"100%","max-height":"100%"},attrs:{"src":require("@/assets/marketplace/no_image.svg"),"alt":"no_image"}})])}),0),_c('div',{staticClass:"order-subtitle"},[_c('span',{staticClass:"order-price"},[_vm._v(" "+_vm._s(parseFloat(order.total_excl_tax).toLocaleString("ru"))+" ₸ ")]),_c('span',{staticClass:"order-product-count"},[_vm._v(" Количество товаров: "+_vm._s(order.lines.length)+" ")])])]),(index !== _vm.approveOrders.length - 1)?_c('hr',{staticClass:"market-divider"}):_vm._e()])}),1)],1),(_vm.isApproveOrdersLoaded && !_vm.isLastPage)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"quiet":true}}],staticStyle:{"margin-bottom":"72px","background-color":"#FFFFFF"}},[_c('hr',{staticClass:"market-divider"}),_c('div',{staticClass:"skeleton-orders-container"},[_c('v-skeleton-loader',{staticClass:"skeleton-orders-heading",attrs:{"type":"text@2"}}),_c('v-skeleton-loader',{staticClass:"skeleton-orders-images",attrs:{"type":"avatar@3"}}),_c('v-skeleton-loader',{staticClass:"skeleton-orders-footer",attrs:{"type":"heading, text"}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }