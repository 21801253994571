<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="button"
      :width="64"
      :height="32"
    />
    <template v-else>
      <div v-if="limitType === 'garwin'" class="cart-limit-container">
        <img src="@/assets/marketplace/limit_icon.svg" alt="limit" />
        <span class="cart-limit-amount">
        {{ limit ? `${limit.toLocaleString("ru")} ₸` : "Нет лимита" }}
      </span>
      </div>
      <div v-else class="limit-tag is-stationery">
      <span>
        {{ stationeryLimit ? `${stationeryLimit.toLocaleString("ru")} ₸` : "Нет лимита" }}
      </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "LimitTag",
  props: {
    isLoading: Boolean,
    default: () => false
  },
  computed: {
    ...mapState("marketplace", ["limit", "limitType", "stationeryLimit"]),
  },
}
</script>

<style lang="scss">
.limit-tag {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;

  &.is-stationery {
    background-color: #A1D8FF;
    color: #3D7CAF;
  }
}
</style>
