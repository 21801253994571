<template>
  <div class="delegate-container" :class="{ active: delegateDialog }">
    <header class="market-header-container">
      <div class="market-header-card">
        <div class="market-header-title">
          <span class="cart-header-title" style="margin: 0 auto">
            Выбор сотрудника
          </span>
          <button
            type="button"
            class="header-right-icon"
            @click="emitCloseDelegateDialog"
          >
            <img src="@/assets/marketplace/cancel_icon.svg" alt="edit" />
          </button>
        </div>
      </div>
    </header>
    <div class="market-container">
      <div
        v-for="(delegate, index) in delegates"
        :key="delegate.pk"
        @click="selectDelegate(delegate)"
      >
        <div class="delegate-item">
          {{ delegate.full_name }}
          <img
            v-show="selectedDelegate && selectedDelegate.pk === delegate.pk"
            src="@/assets/marketplace/check.svg"
            alt="check"
            style="margin-left: 20px"
          />
        </div>
        <hr v-if="index !== delegates.length - 1" class="market-divider">
      </div>
    </div>
    <div class="delegate-order-actions">
      <button
        type="button"
        :disabled="!selectedDelegate"
        class="delegate-order-btn"
        @click="confirmDelegation"
      >
        Подтвердить
      </button>
    </div>
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="confirmAlert"
    />
    <LoadingDialog ref="loadingDialog" />
  </div>
</template>

<script>
import AlertDialog from "@/components/marketplace/alert_dialog.vue";
import LoadingDialog from "@/components/marketplace/loading_dialog.vue";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    delegateDialog: Boolean,
    orderId: [Number, String],
  },
  data: () => ({
    selectedDelegate: undefined,
    alertInfo: {},
    alertDialog: false,
  }),
  components: {
    AlertDialog,
    LoadingDialog,
  },
  computed: {
    ...mapState("marketplace", ["delegates"]),
  },
  methods: {
    ...mapActions("marketplace", ["setDelegate"]),
    emitCloseDelegateDialog() {
      this.selectedDelegate = undefined;
      this.$emit('closeDelegateDialog');
    },
    selectDelegate(delegate) {
      if (this.selectedDelegate?.pk === delegate.pk) {
        this.selectedDelegate = undefined;
      } else {
        this.selectedDelegate = delegate;
      }
    },
    confirmDelegation() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: `${this.selectedDelegate.full_name} сможет принять заказ и может увидеть его в своём разделе “История заказов”`,
        confirm_btn_text: "Да",
        cancel_btn_text: "Нет",
      };
      this.alertDialog = true;
    },
    async proceedDelegation() {
      try {
        this.$refs.loadingDialog.startLoading();
        await this.setDelegate({
          orderId: this.orderId,
          userId: this.selectedDelegate.pk,
        });
        this.$emit('updateOrder');
        this.$refs.loadingDialog.stopLoading();
        this.alertInfo = {
          alert_title: "Заказ делегирован",
          alert_message: `Теперь ${this.selectedDelegate.full_name} сможет принять заказ в ваше отсутствие`,
          cancel_btn_text: "Ок",
        };
        this.alertDialog = true;
      } catch (err) {
        let errMessage = err.response ? err.response?.data.message : err;
        this.alertInfo = {
          alert_title: "Ошибка при делегировании",
          alert_message: errMessage,
          cancel_btn_text: "Ок",
        };
        this.alertDialog = true;
      } finally {
        this.$refs.loadingDialog.stopLoading();
      }
    },
    closeAlert() {
      this.alertDialog = false;
      setTimeout(() => {
        this.alertInfo = {};
        this.emitCloseDelegateDialog();
      }, 300);
    },
    confirmAlert() {
      this.alertDialog = false;
      this.proceedDelegation();
    },
  },
}
</script>

<style lang="scss" scoped>
.delegate-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  overflow-y: scroll;
  background-color: #F9F9F9;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transition: transform 0.3s ease;
}
.delegate-container.active {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.delegate-item {
  padding: 20px 16px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.delegate-order-actions {
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
}
.delegate-order-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.delegate-order-btn:disabled {
  background: rgba(239, 127, 26, 0.08);
  color: #8e8e93;
}
</style>