import { render, staticRenderFns } from "./feedback.vue?vue&type=template&id=00949138&scoped=true&"
import script from "./feedback.vue?vue&type=script&lang=js&"
export * from "./feedback.vue?vue&type=script&lang=js&"
import style0 from "./feedback.vue?vue&type=style&index=0&id=00949138&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00949138",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VRating})
