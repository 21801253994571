<template>
  <main data-app class="layout__body no-padding">
    <header v-if="acceptOrder" class="market-header-container">
      <div class="market-header-card">
        <div class="market-header-title">
          <div class="cart-header-title" style="margin: 0 auto">
            <span v-if="is_accepting"> Выбор доставленного товара </span>
            <span v-else> Заказ #{{ acceptOrder.number }} </span>
            <button
              v-if="is_accepting"
              type="button"
              class="header-right-icon"
              @click="toggleAcceptingOrder"
            >
              <img src="@/assets/marketplace/cancel_icon.svg" alt="edit" />
            </button>
          </div>
        </div>
      </div>
    </header>
    <div
      v-if="acceptOrder"
      class="market-container grey-bg"
      :class="{ 'mb-160': isLinesAcceptable }"
    >
      <div class="order-info">
        <div class="order-price-info">
          <span class="order-price-title">Итоговая сумма</span>
          <span class="order-price">
            {{ parseFloat(acceptOrder.total_excl_tax).toLocaleString("ru") }} ₸
          </span>
        </div>
        <div class="d-flex">
          <div>
            <div class="order-status-info" style="margin-bottom: 12px">
              <span class="order-status-title">Статус</span>
              <span
                class="order-status orange-status"
                :class="{
                  'red-status': statuses.order__cancelled.includes(
                    acceptOrder.status
                  ),
                  'green-status': statuses.order__completed.includes(
                    acceptOrder.status
                  ),
                }"
              >
                {{ acceptOrder.status }}
              </span>
            </div>
            <div class="order-status-info" style="margin-bottom: 12px">
              <span class="order-status-title">Заказ создан</span>
              <span class="order-status">
                {{ acceptOrder.owner.full_name }}
              </span>
            </div>
          </div>
          <div>
            <div class="order-product-amount-info" style="margin-bottom: 12px">
              <span class="order-product-amount-title">Количество товаров</span>
              <span class="order-product-amount">
                {{ acceptOrder.lines.length }}
              </span>
            </div>
            <div class="order-product-amount-info" style="margin-bottom: 12px">
              <span class="order-product-amount-title">
                Дата создания заказа
              </span>
              <span class="order-product-amount">
                {{ formattedCreatedAtDate + " " + formattedCreatedAtTime }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div v-if="acceptOrder.delegated_to">
            <div
              v-if="marketUser.full_name === acceptOrder.delegated_to.full_name"
              class="order-status-info"
            >
              <span class="order-status-title">
                Делегирован от:
              </span>
              <span class="order-status">
                {{ acceptOrder.owner.full_name }}
              </span>
            </div>
            <div v-else class="order-status-info">
              <span class="order-status-title">
                Делегирован:
              </span>
              <span class="order-status">
                {{ acceptOrder.delegated_to.full_name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="order-products-container">
        <div
          v-if="is_accepting"
          class="d-flex align-items-center"
          :class="{ 'added-to-accept': isAllItemsCancelling }"
          @click="addAllToCancelQueue({ is_editing: is_accepting, lineIds })"
        >
          <img
            v-if="isAllItemsCancelling"
            src="@/assets/marketplace/check.svg"
            alt="check"
            style="margin-left: 16px"
          />
          <div v-else class="order-unchecked"></div>
          <span style="padding: 16px">Выделить все</span>
        </div>
        <div
          v-for="(line, index) in filteredLines"
          :key="line.id"
          :class="{
            'added-to-accept': cancelQueue.includes(line.id),
            'not-accepting-line': is_accepting
              ? !lineIds.includes(line.id)
              : false,
          }"
          @click="checkStatusAndAddToCancelQueue(line.id, line.status)"
        >
          <div class="d-flex align-items-center">
            <div v-if="is_accepting">
              <div
                v-if="!cancelQueue.includes(line.id)"
                class="order-unchecked"
              ></div>
              <img
                v-show="cancelQueue.includes(line.id)"
                src="@/assets/marketplace/check.svg"
                alt="check"
                style="margin-left: 16px"
              />
            </div>
            <div class="order-products">
              <div class="order-product-status-and-delivery">
                <div
                  class="order-product-status orange-status"
                  :class="{
                    'red-status': statuses.line__cancelled.includes(
                      line.status
                    ),
                    'green-status': statuses.line__completed.includes(
                      line.status
                    ),
                  }"
                >
                  <span>{{ line.status }}</span>
                </div>
                <div
                  v-if="line.preliminary_delivery_date"
                  class="order-product-delivery"
                >
                  <img
                    src="@/assets/marketplace/delivery_icon.svg"
                    alt="devilery"
                  />
                  <span>
                    {{
                      new Date(
                        line.preliminary_delivery_date
                      ).toLocaleDateString("ru", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })
                    }}
                  </span>
                </div>
              </div>
              <div class="order-photo-container">
                <img
                  v-if="line.product.image"
                  :src="api_url + line.product.image"
                  alt="product"
                  style="max-width: 100%; max-height: 100%"
                />
                <img
                  v-else
                  src="@/assets/marketplace/no_image.svg"
                  alt="no_image"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
              <span class="order-product-vendor-code">
                Артикул: {{ line.stockrecord.partner_sku }}
              </span>
              <span class="order-product-title">{{ line.product.title }}</span>
              <div class="d-flex align-items-center">
                <span class="order-product-price">
                  {{ parseFloat(line.price_excl_tax).toLocaleString("ru") }} ₸
                </span>
                <span class="order-product-quantity">
                  {{ line.quantity }} шт.
                </span>
              </div>
            </div>
          </div>
          <hr
            v-if="index !== filteredLines.length - 1"
            class="market-divider"
          />
        </div>
      </div>
      <div
        v-if="isLinesAcceptable && !is_accepting"
        class="accept-order-container"
      >
        <button
          type="button"
          class="accept-order-btn"
          @click="toggleAcceptingOrder"
        >
          <span>Приёмка товара</span>
        </button>
      </div>
      <div v-if="is_accepting" class="accept-order-actions-container">
        <button
          type="button"
          class="reject-line-btn"
          :disabled="cancelQueue.length === 0"
          @click="confirmRejectLines"
        >
          <span>Отклонить</span>
        </button>
        <button
          type="button"
          class="accept-line-btn"
          :disabled="cancelQueue.length === 0"
          @click="confirmAcceptLines"
        >
          <span>Принять</span>
        </button>
      </div>
    </div>
    <div v-if="errorMessage" class="go-to-main-container">
      <span class="go-to-main-text">{{ errorMessage }}</span>
      <button
        type="button"
        class="go-to-main-btn"
        @click="$router.replace({ name: 'Market' })"
      >
        На главную
      </button>
    </div>
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertRejectDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="rejectLines"
    />
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertAcceptDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="acceptLines"
    />
    <LoadingDialog ref="loadingDialog" />
  </main>
</template>

<script>
import AlertDialog from "@/components/marketplace/alert_dialog.vue";
import LoadingDialog from "@/components/marketplace/loading_dialog.vue";
import { mapActions, mapState } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";

export default {
  props: {
    number: [Number, String],
  },
  data: () => ({
    is_accepting: false,
    lineIds: [],
    alertInfo: {},
    alertRejectDialog: false,
    alertAcceptDialog: false,
    errorMessage: "",
  }),
  components: {
    AlertDialog,
    LoadingDialog,
  },
  computed: {
    ...mapState("marketplace", [
      "api_url",
      "marketUser",
      "statuses",
      "acceptOrder",
      "cancelQueue",
    ]),
    formattedCreatedAtDate() {
      return new Date(this.acceptOrder?.date_placed).toLocaleDateString("ru", {
        day: "numeric",
        month: "2-digit",
        year: "2-digit",
      });
    },
    formattedCreatedAtTime() {
      return new Date(this.acceptOrder?.date_placed).toLocaleTimeString("ru", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    ownerAcceptableLines() {
      return this.acceptOrder?.lines.filter((line) =>
        this.statuses.line__acceptable_by_owner.find(
          (status) => status === line.status
        )
      );
    },
    warehouseAcceptableLines() {
      return this.ownerAcceptableLines.filter((line) =>
        this.statuses.line__acceptable_by_warehouse.find(
          (status) => status === line.status
        )
      );
    },
    ownerNotAcceptableLines() {
      return this.acceptOrder?.lines.filter(
        (line) =>
          !this.statuses.line__acceptable_by_owner.find(
            (status) => status === line.status
          )
      );
    },
    warehouseNotAcceptableLines() {
      return this.acceptOrder?.lines.filter(
        (line) =>
          !this.statuses.line__acceptable_by_warehouse.find(
            (status) => status === line.status
          )
      );
    },
    isOwnerAccepting() {
      return this.acceptOrder?.owner?.full_name === this.marketUser.full_name
        ? true
        : false;
    },
    filteredLines() {
      if (this.isOwnerAccepting) {
        return this.ownerAcceptableLines.concat(this.ownerNotAcceptableLines);
      } else {
        return this.warehouseAcceptableLines.concat(this.warehouseNotAcceptableLines);
      }
    },
    isLinesAcceptable() {
      if (this.ownerAcceptableLines && this.ownerAcceptableLines.length > 0)
        return true;
      else return false;
    },
    isAllItemsCancelling() {
      if (
        this.isOwnerAccepting &&
        this.ownerAcceptableLines.length === this.cancelQueue.length &&
        this.ownerAcceptableLines.length !== 0
      )
        return true;
      else if (
        !this.isOwnerAccepting &&
        this.warehouseAcceptableLines.length === this.cancelQueue.length &&
        this.warehouseAcceptableLines.length !== 0
      )
        return true;
      else return false;
    },
  },
  async created() {
    await this.tryToGetAcceptOrder();
    this.getLineIds();
  },
  mounted() {
    backNavigateQueue.set(this.backToAcceptOrders);
  },
  methods: {
    ...mapActions("marketplace", [
      "getAcceptOrder",
      "clearAcceptOrder",
      "addToCancelQueue",
      "addAllToCancelQueue",
      "clearCancelQueue",
      "acceptOrderedLines",
      "rejectOrderedLines",
    ]),
    async tryToGetAcceptOrder() {
      try {
        await this.getAcceptOrder(this.number);
      } catch (err) {
        let errorMessage = err.response.data.detail;
        let errorMessageData = err.response.data;
        if (this.acceptOrder) {
          this.backToAcceptOrders();
        } else {
          if (errorMessage) {
            this.errorMessage = errorMessage;
          } else {
            this.errorMessage = errorMessageData;
          }
        }
      }
    },
    async getLineIds() {
      this.lineIds = [];
      if (this.isOwnerAccepting) {
        this.ownerAcceptableLines.forEach((line) => {
          this.lineIds = [...this.lineIds, line.id];
        });
      } else {
        this.warehouseAcceptableLines.forEach((line) => {
          this.lineIds = [...this.lineIds, line.id];
        });
      }
    },
    checkStatusAndAddToCancelQueue(lineId, lineStatus) {
      if (
        this.isOwnerAccepting &&
        this.statuses.line__acceptable_by_owner.includes(lineStatus)
      ) {
        this.addToCancelQueue({
          is_editing: this.is_accepting,
          lineId,
        });
      } else if (
        !this.isOwnerAccepting &&
        this.statuses.line__acceptable_by_warehouse.includes(lineStatus)
      ) {
        this.addToCancelQueue({
          is_editing: this.is_accepting,
          lineId,
        });
      }
    },
    confirmRejectLines() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: "Вы точно хотите отклонить выделенные позиции?",
        cancel_btn_text: "Нет",
        confirm_btn_text: "Да",
      };
      this.alertRejectDialog = true;
    },
    confirmAcceptLines() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: "Вы точно хотите принять выделенные позиции?",
        cancel_btn_text: "Нет",
        confirm_btn_text: "Да",
      };
      this.alertAcceptDialog = true;
    },
    closeAlert() {
      if (this.alertRejectDialog) this.alertRejectDialog = false;
      if (this.alertAcceptDialog) this.alertAcceptDialog = false;
      this.alertInfo = {};
    },
    async rejectLines() {
      this.alertRejectDialog = false;
      this.alertInfo = {};
      this.$refs.loadingDialog.startLoading();
      await this.rejectOrderedLines({
        orderNumber: this.number,
        linesArray: this.cancelQueue,
      });
      try {
        await this.tryToGetAcceptOrder();
        this.toggleAcceptingOrder();
        this.getLineIds();
        this.$refs.loadingDialog.stopLoading();
      } catch (err) {
        if (err) this.backToAcceptOrders();
        this.toggleAcceptingOrder();
        this.getLineIds();
      }
    },
    async acceptLines() {
      this.alertAcceptDialog = false;
      this.alertInfo = {};
      this.$refs.loadingDialog.startLoading();
      await this.acceptOrderedLines({
        orderNumber: this.number,
        linesArray: this.cancelQueue,
      });
      try {
        await this.tryToGetAcceptOrder();
        this.toggleAcceptingOrder();
        this.getLineIds();
        this.$refs.loadingDialog.stopLoading();
      } catch (err) {
        if (err) this.backToAcceptOrders();
        this.toggleAcceptingOrder();
        this.getLineIds();
      }
    },
    backToAcceptOrders() {
      this.$router.replace({ name: "AcceptOrders" });
    },
    toggleAcceptingOrder() {
      this.is_accepting = !this.is_accepting;
      if (this.is_accepting === false) {
        this.clearCancelQueue("");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.is_accepting = false;
    this.clearCancelQueue("");
    this.lineIds = [];
    this.clearAcceptOrder();
    next();
  },
};
</script>

<style lang="scss" scoped>
.mb-160 {
  margin-bottom: 160px;
}
.added-to-accept {
  border-radius: 16px;
  background: rgba(239, 127, 26, 0.08);
}
.not-accepting-line {
  opacity: 0.5;
  border-radius: 16px;
  background: #f9f9f9;
}
.accept-order-container {
  margin-bottom: 64px; //64px for footer
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
}
.accept-order-actions-container {
  margin-bottom: 64px; //64px for footer
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
}
.accept-order-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;
}
.accept-order-btn > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.reject-line-btn,
.accept-line-btn {
  padding: 16px;
  height: 56px;
  width: calc(50% - 4px);
  border-radius: 16px;
}
.reject-line-btn > span,
.accept-line-btn > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.reject-line-btn {
  background: #ef7f1a;
}
.accept-line-btn {
  background: #90bc34;
}
.reject-line-btn:disabled {
  background: rgba(239, 127, 26, 0.08);
}
.accept-line-btn:disabled {
  background: rgba(144, 188, 52, 0.08);
}
.reject-line-btn:disabled > span,
.accept-line-btn:disabled > span {
  color: #8e8e93;
}
.go-to-main-container {
  margin-top: 30%;
  padding: 0 48px !important;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.go-to-main-text {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
}
.go-to-main-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  border-radius: 16px;
  background: #ef7f1a;
}
</style>