<template>
  <main
    class="layout__body no-padding"
    :class="{ 'prevent-scrolling': isSearchOpened }"
  >
    <header class="market-fixed-header" ref="header">
      <div class="market-header-card">
        <div class="market-header-title">
          <span class="market-header-main">Магазин</span>
          <LimitTag :is-loading="!showCategories"/>
<!--          <div v-if="limitType === 'garwin'" class="cart-limit-container">-->
<!--            <img src="@/assets/marketplace/limit_icon.svg" alt="limit" />-->
<!--            <span class="cart-limit-amount">-->
<!--              {{ limit ? `${limit.toLocaleString("ru")} ₸` : "Нет лимита" }}-->
<!--            </span>-->
<!--          </div>-->
<!--          <div v-else class="cart-limit-container">-->
<!--            <span class="cart-limit-amount">-->
<!--              {{ stationeryLimit ? `₸ ${stationeryLimit.toLocaleString("ru")}` : "Нет лимита" }}-->
<!--            </span>-->
<!--          </div>-->
        </div>
        <MarketSearch
          @disableScroll="disableScroll"
          @enableScroll="enableScroll"
        />
      </div>
    </header>
    <div class="market-container" :style="{ marginTop: headerHeight + 8 + 'px' }">
      <v-skeleton-loader
        v-if="!showCategories"
        type="list-item@10"
        class="skeleton-category"
      ></v-skeleton-loader>
      <NoProducts v-else-if="categories.length === 0"/>
      <div v-else>
        <router-link
          v-for="(tab, index) in categories"
          :key="tab.id"
          :to="{ name: 'Subcategory', params: { subcategoryId: tab.id } }"
        >
          <div class="market-category-item">
            <div class="market-category-left">
              <img
                v-if="!tab.image"
                :src="getIcon(tab.name_code)"
                class="market-category-img-static"
              />
              <span class="market-category-text">{{ tab.name }}</span>
            </div>
            <div v-if="productClass !== 'office_supplies'" class="market-category-right">
              <span class="market-category-products-count">
                {{ tab.products_count }}
              </span>
              <img src="@/assets/marketplace/chevron_right.svg" alt="right" />
            </div>
          </div>
          <hr v-if="index !== categories.length - 1" class="market-divider" />
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import MarketSearch from "@/components/marketplace/market_search.vue";
import { mapState, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import LimitTag from "@/components/marketplace/LimitTag.vue"
import NoProducts from "@/components/merch/NoProducts.vue"

export default {
  components: {
    NoProducts,
    LimitTag,
    MarketSearch,
  },
  data: () => ({
    headerHeight: undefined,
    isSearchOpened: false,
    showCategories: false,
    productClass: 'garwin'
  }),
  computed: {
    ...mapState("marketplace", ["limit", "limitType", "stationeryLimit", "categories"]),
  },
  async created() {
    const product_class = this.$route.query?.product_class ?? 'garwin'
    this.productClass = product_class
    await this.getUserInfo();
    await this.getCategories(product_class);
    this.showCategories = true
  },
  mounted() {
    backNavigateQueue.set(this.exitFromMarket);
    this.$store.dispatch('layout/setHeaderTitle', 'Marketplace');
    this.getHeaderHeight();
  },
  methods: {
    ...mapActions("marketplace", ["getUserInfo", "getCategories"]),
    getHeaderHeight() {
      this.headerHeight = this.$refs.header?.clientHeight;
      this.$forceUpdate();
    },
    getIcon(name) {
      if (this.productClass === 'garwin') {
        try {
          return require(`@/assets/marketplace/categories/${name}.svg`)
        } catch (e) {
          return require(`@/assets/marketplace/categories/default.svg`)
        }
      } else {
        try {
          return require(`@/assets/marketplace/categories/stationery/${name}.svg`)
        } catch (e) {
          return require(`@/assets/marketplace/categories/default.svg`)
        }
      }
    },
    disableScroll() {
      this.isSearchOpened = true;
    },
    enableScroll() {
      this.isSearchOpened = false;
    },
    exitFromMarket() {
      this.$router.replace({ name: 'Marketplace' });
    },
  },
};
</script>

<style lang="scss" scoped>
.market-header-card, .market-container {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
