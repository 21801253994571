<template>
  <main
    class="layout__body no-padding"
    :class="{ 'prevent-scrolling': isSearchOpened }"
  >
    <div v-if="wide_sub_subcategories">
      <ListOfProducts
        ref="listOfProducts"
        :categoryId="sub_subcategoryId"
        :sub_subcategory="true"
        @disableScroll="disableScroll"
        @enableScroll="enableScroll"
      />
    </div>
    <div v-else>
      <header class="market-fixed-header" ref="header">
        <div class="market-header-card">
          <div class="market-header-title">
            <div class="market-header-with-back-btn">
              <v-skeleton-loader
                v-if="!sub_subcategory"
                type="text"
                class="skeleton-header"
                width="150"
              ></v-skeleton-loader>
              <span v-else class="market-header-category">
                {{ sub_subcategory.name }}
              </span>
            </div>
            <LimitTag />
          </div>
          <MarketSearch
            @disableScroll="disableScroll"
            @enableScroll="enableScroll"
          />
        </div>
      </header>
      <div class="market-container" :style="{ marginTop: headerHeight + 8 + 'px' }">
        <v-skeleton-loader
          v-if="!sub_subcategoryById"
          type="list-item@10"
          class="skeleton-category"
        ></v-skeleton-loader>
        <div v-else>
          <div @click="allProductsClicked">
            <div class="market-category-item">
              <div class="market-category-left">
                <span class="market-category-text">Все товары</span>
              </div>
              <div class="market-category-right">
                <span class="market-category-products-count">
                  {{ sub_subcategory.products_count }}
                </span>
                <img src="@/assets/marketplace/chevron_right.svg" alt="right" />
              </div>
            </div>
            <hr v-if="sub_subcategoryById.length" class="market-divider" />
          </div>
          <router-link
            v-for="(sub_subcategory, index) in sub_subcategoryById"
            :key="sub_subcategory.id"
            :to="(sub_subcategory.numchild > 0) ?
              { name: 'Sub-sub-subcategory', params: { sub_sub_subcategoryId: sub_subcategory.id } } :
              { name: 'List of products', params: { categoryId: sub_subcategory.id, level: 2 } }
            "
          >
            <div class="market-category-item">
              <div class="market-category-left">
                <span class="market-category-text">{{ sub_subcategory.name }}</span>
              </div>
              <div class="market-category-right">
                <span class="market-category-products-count">
                  {{ sub_subcategory.products_count }}
                </span>
                <img src="@/assets/marketplace/chevron_right.svg" alt="right" />
              </div>
            </div>
            <hr v-if="index !== sub_subcategoryById.length - 1" class="market-divider" />
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ListOfProducts from "@/views/marketplace/list_of_products.vue";
import MarketSearch from "@/components/marketplace/market_search.vue";
import { mapState, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";
import LimitTag from "@/components/marketplace/LimitTag.vue"

export default {
  components: {
    LimitTag,
    ListOfProducts,
    MarketSearch,
  },
  props: {
    sub_subcategoryId: [Number, String],
  },
  data: () => ({
    headerHeight: undefined,
    isSearchOpened: false,
  }),
  computed: {
    ...mapState("marketplace", [
      "limit",
      "wide_subcategories",
      "wide_sub_subcategories",
      "subcategoryById",
      "sub_subcategoryById"
    ]),
    sub_subcategory () {
      return this.subcategoryById.find(sub_subcategory => {
        return sub_subcategory.id == this.sub_subcategoryId;
      })
    },
  },
  async created() {
    await this.getSubSubcategoryById(this.sub_subcategoryId);
    if (this.wide_sub_subcategories) this.$refs.listOfProducts.getHeaderHeight();
    amplitudeMarketplace({
      event_type: "Инструменты - Переход в категорию",
      extra_info: {
        level: 2,
        title: this.sub_subcategory.name
      },
      project_name: "Marketplace"
    });
  },
  mounted() {
    backNavigateQueue.set(this.backToMainMarket);
    this.getHeaderHeight();
  },
  methods: {
    ...mapActions("marketplace", [
      "set_wide_sub_subcategories",
      "reset_wide_subcategories",
      "reset_wide_sub_subcategories",
      "getSubSubcategoryById",
      "clearSubcategory",
      "clearSubSubcategory",
      "clearProductsPaginationInfo",
      "clearListOfProducts",
      "clearBrandsByCategory",
      "clearQueryParams",
      "clearFiltersPaginationInfo",
      "clearProductsByFilter",
    ]),
    getHeaderHeight() {
      let headerRef = this.$refs.header;
      this.headerHeight = headerRef ? headerRef.clientHeight : 0;
      this.$forceUpdate();
    },
    scrollToTop() {
      window.scrollTo({ top: 0 });
    },
    backToMainMarket() {
      this.$router.go(-1);
    },
    disableScroll() {
      this.isSearchOpened = true;
    },
    enableScroll() {
      this.isSearchOpened = false;
    },
    allProductsClicked() {
      this.set_wide_sub_subcategories();
      amplitudeMarketplace({
        event_type: "Инструменты - Переход во Все товары",
        extra_info: null,
        project_name: "Marketplace"
      });
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (to.name === 'Subcategory') {
      if (this.wide_sub_subcategories && !this.wide_subcategories) {
        await this.reset_wide_sub_subcategories();
        this.clearProductsPaginationInfo();
        this.clearListOfProducts();
        this.clearBrandsByCategory();
        this.clearQueryParams();
        this.clearFiltersPaginationInfo();
        this.clearProductsByFilter();
        this.getHeaderHeight();
        this.scrollToTop();
        next(false);
      } else {
        this.clearSubSubcategory();
        this.clearProductsPaginationInfo();
        this.clearListOfProducts();
        this.clearBrandsByCategory();
        this.clearQueryParams();
        this.clearFiltersPaginationInfo();
        this.clearProductsByFilter();
        next();
      }
    } else if (
      to.name !== 'Sub-sub-subcategory' &&
      to.name !== 'List of products' &&
      to.name !== 'ProductById'
    ) {
      if (to.params.isFromFooter) await this.reset_wide_subcategories();
      this.reset_wide_sub_subcategories();
      this.clearSubcategory();
      this.clearSubSubcategory();
      this.clearProductsPaginationInfo();
      this.clearListOfProducts();
      this.clearBrandsByCategory();
      this.clearQueryParams();
      this.clearFiltersPaginationInfo();
      this.clearProductsByFilter();
      next();
    } else if (
      to.name === 'Sub-sub-subcategory' ||
      to.name === 'List of products'
    ) {
      this.clearProductsPaginationInfo();
      this.clearListOfProducts();
      this.clearBrandsByCategory();
      this.clearQueryParams();
      this.clearFiltersPaginationInfo();
      this.clearProductsByFilter();
      next();
    } else next();
  },
};
</script>

<style lang="scss" scoped>
.market-header-card, .market-container {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
