<template>
  <main class="layout__body no-padding">
    <header class="market-header-container">
      <div class="market-header-card">
        <div class="market-header-title">
          <span class="support-text">Служба поддержки</span>
        </div>
      </div>
    </header>
    <div class="market-container">
      <div class="support-card">
        <h5 class="support-card-title">Контактные номера</h5>
        <div class="support-list">
          <div class="support-item">
            <span class="support-item-title">Поставщик по Инструментам Garwin (Актобе, Рудный)</span>
            <div class="support-item-subtitle">
              <span class="support-item-number">
                +7 (771) 024 82 66
              </span>
              <a href="tel:+77710248266" @click="sendToAmplitude">
                <img src="@/assets/marketplace/support_call.svg" alt="call" />
              </a>
            </div>
            <div class="support-item-subtitle" style="margin-top: 8px">
              <span class="support-item-number">
                +7 (771) 024 83 87
              </span>
              <a href="tel:+77710248387" @click="sendToAmplitude">
                <img src="@/assets/marketplace/support_call.svg" alt="call" />
              </a>
            </div>
          </div>
          <div class="support-item">
            <span class="support-item-title">Поставщик по Инструментам Garwin (Павлодар)</span>
            <div class="support-item-subtitle">
              <span class="support-item-number">
                +7 (771) 057 18 40
              </span>
              <a href="tel:+77710571840" @click="sendToAmplitude">
                <img src="@/assets/marketplace/support_call.svg" alt="call" />
              </a>
            </div>
            <div class="support-item-subtitle" style="margin-top: 8px">
              <span class="support-item-number">
                +7 (771) 057 18 41
              </span>
              <a href="tel:+77710571841" @click="sendToAmplitude">
                <img src="@/assets/marketplace/support_call.svg" alt="call" />
              </a>
            </div>
          </div>
          <div class="support-item">
            <span class="support-item-title">Коммерческий центр</span>
            <div class="support-item-subtitle">
              <span class="support-item-number">+7 (7172) 612 370</span>
              <a href="tel:+77172612370">
                <img src="@/assets/marketplace/support_call.svg" alt="call" />
              </a>
            </div>
          </div>
          <div class="support-item">
            <span class="support-item-title">Техническая поддержка</span>
            <div class="support-item-subtitle">
              <a
                href="https://smarterg.app.link/igcbPnGG0tb"
                target="_top"
                class="support-item-link"
              >
                https://smarterg.app.link/igcbPnGG0tb
              </a>
              <a href="https://smarterg.app.link/igcbPnGG0tb" target="_top">
                <img src="@/assets/marketplace/chat_icon.svg" alt="chat" height="14" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";

export default {
  mounted() {
    backNavigateQueue.set(this.exitFromMarket);
  },
  methods: {
    sendToAmplitude() {
      amplitudeMarketplace({
        event_type: "Инструменты - Звонок поставщику в Поддержке",
        extra_info: null,
        project_name: "Marketplace"
      });
    },
    exitFromMarket() {
      this.$router.go(-1);
    },
  },
};
</script>
