<template>
  <main data-app v-if="approveOrder" class="layout__body no-padding">
    <header class="market-header-container">
      <div class="market-header-card">
        <div class="market-header-title">
          <div class="cart-header-title">
            <span> Заказ #{{ approveOrder.number }} </span>
          </div>
          <LimitTag />
        </div>
      </div>
    </header>
    <div class="market-container grey-bg mb-160">
      <div class="order-info">
        <div class="order-price-info">
          <span class="order-price-title">Итоговая сумма</span>
          <span class="order-price">
            {{ parseFloat(approveOrder.total_excl_tax).toLocaleString("ru") }}
            ₸
          </span>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <div class="order-status-info" style="margin-bottom: 12px">
              <span class="order-status-title">Статус</span>
              <span
                class="order-status orange-status"
                :class="{
                  'red-status': statuses.order__cancelled.includes(
                    approveOrder.status
                  ),
                  'green-status': statuses.order__completed.includes(
                    approveOrder.status
                  ),
                }"
              >
                {{ approveOrder.status }}
              </span>
            </div>
            <div class="order-status-info">
              <span class="order-status-title">Заказ создан</span>
              <span class="order-status">
                {{ approveOrder.owner.full_name }}
              </span>
            </div>
          </div>
          <div>
            <div class="order-product-amount-info" style="margin-bottom: 12px">
              <span class="order-product-amount-title">Количество товаров</span>
              <span class="order-product-amount">
                {{ approveOrder.lines.length }}
              </span>
            </div>
            <div class="order-product-amount-info">
              <span class="order-product-amount-title">
                Дата создания заказа
              </span>
              <span class="order-product-amount">
                {{ formattedCreatedAtDate + " " + formattedCreatedAtTime }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="order-products-container">
        <div v-for="(line, index) in approveOrder.lines" :key="line.id">
          <div class="order-products">
            <div class="order-photo-container">
              <img
                v-if="line.product.image"
                :src="api_url + line.product.image"
                alt="product"
                style="max-width: 100%; max-height: 100%"
              />
              <img
                v-else
                src="@/assets/marketplace/no_image.svg"
                alt="no_image"
                style="max-width: 100%; max-height: 100%"
              />
            </div>
            <span class="order-product-vendor-code">
              Артикул: {{ line.stockrecord.partner_sku }}
            </span>
            <span class="order-product-title">{{ line.product.title }}</span>
            <div class="d-flex align-items-center">
              <span class="order-product-price">
                {{ parseFloat(line.price_excl_tax).toLocaleString("ru") }} ₸
              </span>
              <span class="order-product-quantity">
                {{ line.quantity }} шт.
              </span>
            </div>
          </div>
          <hr
            v-if="index !== approveOrder.lines.length - 1"
            class="market-divider"
          />
        </div>
      </div>
      <div v-if="needsApproval" class="approve-order-container">
        <button
          type="button"
          class="decline-order-btn"
          @click="declineDialog = true"
        >
          <span>Отклонить</span>
        </button>
        <button
          type="button"
          class="approve-order-btn"
          @click="confirmApproveOrder"
        >
          <span>Согласовать</span>
        </button>
      </div>
    </div>
    <v-dialog v-model="declineDialog" fullscreen>
      <div class="dialog-bg grey-bg">
        <header class="market-header-container">
          <div class="market-header-card">
            <div class="market-header-title">
              <div class="cart-header-title" style="margin: 0 auto">
                <span>Отклонение заказа</span>
              </div>
            </div>
          </div>
        </header>
        <div class="decline-reasons-container">
          <div
            v-for="(reason, index) in declineReasons"
            :key="index"
            @click="selectedReason = reason"
          >
            <div class="decline-reason">
              <span>{{ reason }}</span>
              <img
                v-if="reason === selectedReason"
                src="@/assets/marketplace/check.svg"
                alt="check"
              />
            </div>
            <hr
              v-if="index !== declineReasons.length - 1"
              class="market-divider"
            />
          </div>
        </div>
        <div
          v-if="selectedReason === 'Другое'"
          class="alternative-reason-container"
        >
          <input
            v-model="alternativeReason"
            type="text"
            placeholder="Укажите другую причину отклонения заказа"
            class="alternative-decline-reason-input"
          />
        </div>
        <div class="decline-order-btn-container">
          <button
            type="button"
            class="decline-order-btn"
            :disabled="!selectedReason"
            @click="confirmDeclineOrder"
          >
            <span>Отклонить заказ</span>
          </button>
        </div>
      </div>
    </v-dialog>
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="confirmAlert"
    />
    <LoadingDialog ref="loadingDialog" />
  </main>
</template>

<script>
import AlertDialog from "@/components/marketplace/alert_dialog.vue";
import LoadingDialog from "@/components/marketplace/loading_dialog.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";
import LimitTag from "@/components/marketplace/LimitTag.vue"

export default {
  props: {
    id: [Number, String],
  },
  data: () => ({
    declineReasons: [
      "Сократить позиции корзины",
      "Бюджет исчерпан",
      "Завести потребность по стандартной процедуре",
      "Рассмотреть аналоги дешевле",
      "Другое",
    ],
    selectedReason: "",
    alternativeReason: "",
    declineDialog: false,
    alertInfo: {},
    alertDialog: false,
  }),
  components: {
    LimitTag,
    AlertDialog,
    LoadingDialog,
  },
  computed: {
    ...mapState("marketplace", [
      "api_url",
      "limit",
      "statuses",
      "approveOrder",
    ]),
    ...mapGetters("marketplace", ["order__can_approve"]),
    formattedCreatedAtDate() {
      return new Date(this.approveOrder.date_placed).toLocaleDateString("ru", {
        day: "numeric",
        month: "2-digit",
        year: "2-digit",
      });
    },
    formattedCreatedAtTime() {
      return new Date(this.approveOrder.date_placed).toLocaleTimeString("ru", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    needsApproval() {
      if (this.approveOrder.status === "На согласовании") return true;
      else return false;
    },
  },
  async created() {
    this.getOrdersToApprove();
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
  },
  methods: {
    ...mapActions("marketplace", [
      "getApproveOrder",
      "clearApproveOrder",
      "approveOrDeclineOrder",
    ]),
    getOrdersToApprove() {
      this.getApproveOrder(this.id);
    },
    handleBackNavigation() {
      if (this.declineDialog) {
        this.closeDeclineDialog();
      } else {
        this.backToApproveOrders();
      }
    },
    backToApproveOrders() {
      this.$router.replace({ name: "ApproveOrders" });
    },
    closeDeclineDialog() {
      this.declineDialog = false;
      this.selectedReason = "";
      this.alternativeReason = "";
    },
    confirmDeclineOrder() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: "Вы точно хотите отклонить этот заказ?",
        cancel_btn_text: "Нет",
        confirm_btn_text: "Да",
      };
      this.alertDialog = true;
    },
    confirmApproveOrder() {
      this.alertInfo = {
        alert_title: "Подтвердите действие",
        alert_message: "Вы точно хотите согласовать этот заказ?",
        cancel_btn_text: "Нет",
        confirm_btn_text: "Да",
      };
      this.alertDialog = true;
    },
    closeAlert() {
      this.alertDialog = false;
      this.alertInfo = {};
    },
    async confirmAlert() {
      this.$refs.loadingDialog.startLoading();
      if (this.declineDialog) {
        await this.approveOrDeclineOrder({
          orderId: this.id,
          approve: false,
          comment:
            this.selectedReason === "Другое"
              ? this.alternativeReason
              : this.selectedReason,
        });
        amplitudeMarketplace({
          event_type: "Инструменты - Согласование заказа",
          extra_info: "Заказ не согласован",
          project_name: "Marketplace"
        });
        this.closeAlert();
        this.declineDialog = false;
      } else {
        await this.approveOrDeclineOrder({
          orderId: this.id,
          approve: true,
        });
        amplitudeMarketplace({
          event_type: "Инструменты - Согласование заказа",
          extra_info: "Заказ согласован",
          project_name: "Marketplace"
        });
        this.closeAlert();
      }
      this.$refs.loadingDialog.stopLoading();
      this.backToApproveOrders();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.clearApproveOrder();
    next();
  },
};
</script>

<style lang="scss" scoped>
.mb-160 {
  margin-bottom: 160px;
}
.added-to-cancel {
  border-radius: 16px;
  background: rgba(239, 127, 26, 0.08);
}
.approve-order-container {
  margin-bottom: 64px; //64px for footer
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  border-radius: 16px 16px 0 0;
  background: #ffffff;
}
.decline-order-btn,
.approve-order-btn {
  padding: 16px;
  height: 56px;
  width: calc(50% - 4px);
  border-radius: 16px;
}
.decline-order-btn > span,
.approve-order-btn > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.decline-order-btn {
  background: #ef7f1a;
}
.approve-order-btn {
  background: #90bc34;
}
.dialog-bg {
  height: 100%;
  width: 100%;
}
.decline-reasons-container {
  margin-bottom: 8px;
  border-radius: 16px;
  background: #ffffff;
}
.decline-reason {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.alternative-reason-container {
  border-radius: 16px;
  background: #ffffff;
}
.alternative-decline-reason-input {
  padding: 16px;
  width: 100%;
}
.alternative-decline-reason-input:focus-visible {
  border-radius: 16px;
  // outline: none;
}
.decline-order-btn-container {
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
}
.decline-order-btn-container > .decline-order-btn {
  width: 100%;
}
.decline-order-btn:disabled {
  background: rgba(239, 127, 26, 0.08);
}
.decline-order-btn:disabled > span {
  color: #8e8e93;
}
</style>
