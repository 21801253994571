<template>
  <div
    @touchstart.stop
    @touchend.stop
    @touchmove.stop
    class="slider-container"
  >
    <Flicking
      :options="{
        align: {
          panel: 0,
          camera: 16,
        },
        adaptive: true,
        interruptable: true,
        circular: true,
        collectStatistics: false,
        overflow: true,
      }"
      :tag="'div'"
      :viewportTag="'div'"
      :cameraTag="'div'"
      :preventClickOnDrag="true"
      :plugins="plugins"
    >
      <!-- <div
        class="main-slider__item"
        v-for="i in 4"
        :key="i"
        :style="{'background-image': 'url(' + require('@/assets/marketplace/main_slider/slider_bg.png') + ')'}"
      >
        <div>
          <p class="main-slider__item__title">Путёвки, мерч и многое другое за баллы</p>
          <div class="main-slider__item__button">Перейти</div>
        </div>
        <img
          :src="require(`@/assets/marketplace/main_slider/slider-${i}.png`)"
          alt="Slider Image"
          class="main-slider__item__image"
          @error="imgLoadErrorHandler"
        >
      </div> -->
      <!-- <div
        class="main-slider__item"
        :style="{'background-image': 'url(' + require('@/assets/marketplace/main_slider/slider_bg.png') + ')'}"
      >
        <div>
          <p class="main-slider__item__title">Добро пожаловать на главную страницу</p>
          <div class="main-slider__item__button">ERG Marketplace</div>
        </div>
        <img
          :src="require(`@/assets/marketplace/main_slider/slider-3.png`)"
          alt="Slider Image"
          class="main-slider__item__image"
          @error="imgLoadErrorHandler"
        >
      </div> -->
      <div
        style="
          height: 118px;
          width: calc(100% - 32px);
          display: flex;
          justify-content: center;
        "
      >
        <img src="@/assets/stationery_banner.svg" alt="" @click="$router.push({ name: 'Discounts' })">
      </div>
      <div slot="viewport" class="flicking-pagination"></div>
    </Flicking>
  </div>
</template>

<script>
import { Flicking }  from "@egjs/vue-flicking";
import { AutoPlay, Pagination } from "@egjs/flicking-plugins"

export default {
  name: "MainSlider",
  components: { Flicking },
  data() {
    return {
      plugins: [
        new Pagination({ type: 'bullet' }),
        new AutoPlay({
          duration: 100000,
          direction: "NEXT"
        }),
      ],
      items: [

      ]
    }
  },
  methods: {
    imgLoadErrorHandler: function(err){
      err.target.parentElement.innerHTML = "";
      // console.error("No image loaded")
    }
  }
}
</script>

<style scoped lang="scss">
.main-slider {
  &__item {
    position: relative;
    background-color: #293046;
    width: calc(100% - 32px);
    height: calc((100vw - 32px)/3);
    margin-right: 28px;
    color: #FFF;
    padding: 24px 16px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background-position: right;
    background-size: contain;

    &--fullscreen{
      padding: 0;
    }

    &__title {
      font-weight: 700;
      font-size: 1.375rem;
      max-width: 65%;
    }
    &__button {
      background: #EF7F1A;
      border-radius: 8px;
      padding: 8px;
      display: inline-block;
      margin-top: 8px;
    }
    &__image {
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc((100vw - 32px)/3);
    }
  }
}
</style>
